import { Component, OnInit, Input, Output } from '@angular/core';
import { Dossier } from '../../shared/model/dossier';

@Component({
  selector: 'app-admin-list-doss',
  templateUrl: './admin-list-doss.component.html',
  styleUrls: ['./admin-list-doss.component.scss']
})
export class AdminListDossComponent implements OnInit {
  @Input()  listDossiers: Dossier[];
  @Output() dossier:Dossier; 
  constructor() { }

  ngOnInit(): void {
  }

}
