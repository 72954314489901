<div fxLayout="column" fxLayout.gt-sm="row wrap" class="p-2">
  <ng-container *ngIf="isAdmin || isManager">
    <div fxFlex="33.3" class="p-2" *ngFor="let dos of dossiers">
      <mat-card class="custom-card">
        <mat-card-header class="bg-primary2 p-1" fxLayoutAlign="space-between center">
          <mat-card-title class="m-0">
            <h3>{{dos.name}}</h3>
          </mat-card-title>
          <button placement="left" [ngbTooltip]="'Créer une liste de dossiers client '" mat-button mat-icon-button
            (click)="uploadFile(dos,$event)">
            <mat-icon>cloud_upload</mat-icon>
          </button>
          <button placement="left" [ngbTooltip]="'Nouveau '" mat-button mat-icon-button (click)="openModal(dos)">
            <mat-icon>create_new_folder</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>
          <p>
            {{dos?.description}}
          </p>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions fxLayoutAlign="center center" class="secondary-text-color">
          <mat-chip-list>
            <mat-chip>
              <h4 class="mb-0 counter" [CountTo]="dos.children.length" [from]="0" [duration]="1"> </h4>
            </mat-chip>
          </mat-chip-list>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>
  <hr>

  <div fxFlex="100" class="p-2" *ngIf="isUser">
    <div>
      <h2>Le QR Code ci-dessous vous permet de vous connecter avec Vusadoc Mobile</h2>
      <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value"
        cssClass="bshadow">
      </ngx-qrcode>
    </div>
  </div>
  <div fxFlex="100" class="p-2" *ngIf="isAdmin || isManager">
    <h1>Guide de démarrage </h1>
    <p> Vous êtes connecté à votre instance VUSADOC avec les droits d'administrateur. Ce guide est fait pour vous aider
      à configurer vos comptes utilisateurs.
      Le but de VUSADOC est de vous aider à créer des espaces d'échange et de stockage de documents et de données entre
      vous et vos clients. Avant toute chose, vous devez créer ces espaces pour chacun de vos utilisateurs auxquels vous
      souhaitez donnéer accès à VUSADOC.
      Pour vous facilter cette tâche, nous avons développé un système d'import simple qui vous permet de préparer un
      fichier Excel contenant l'ensemble de vos clients et de l'intégrer à VUSADOC en 2 clics.
    </p>
    <h2> Procédure d'importation des premiers clients</h2>

    <p>VUSADOC vous permet d'importer facilement vos clients et leurs espaces sont créés de manière automatique lors de
      l'importation. En tant qu'administrateur, cliquez sur la roue crantée <app-feather-icons
        [icon]="'settings'"></app-feather-icons> en haut à droite puis choisissez l'onglet "Utilisateurs".

      Pour importer un fichier Excel contenant vos clients, celui-ci doit respecter le format suivant</p>
    <h3>1. Colonnes</h3>
    <p>Il faut respecter le nommage suivant pour les colonnes.</p>
    <ul>
      <li><b>Nom&nbsp;</b> </li>
      <li><b>Prenom&nbsp;</b></li>
      <li><b>Email&nbsp;</b><i>adresse email depuis lequel le client pourra envoyer des documents dans son espace. Son
          email peut aussi lui servir de Login</i></li>
      <li><b>Login&nbsp;</b><i>Le client pourra se connecter avec ce login. Il doit être unique sinon l'espace client ne
          sera pas créé.</i></li>
      <li><b>Entreprise&nbsp;</b> <i>représente aussi le nom de l'espace client que seuls le client et vous pouvez
          voir.</i></li>
    </ul>
    <h3>2. Feuille</h3>
    <p>Il est très important que la feuille contenant les données soit nommée <b>Data</b>
    </p>

    <h2>Envoi automatique de notifications aux clients</h2>
    <p>Quand vous importez la liste de vos clients, un espace est alors créé pour chacun d'eux. Ils sont notifiés par
      mail de la création de leur espace et sont invités à s'y connecter.
    </p>

  </div>
  <div fxFlex="66.6" class="p-2">
    <h1>Démarrage pour le client</h1>
    <p>
      Lors de la première connexion de l'utilisateur à VUSADOC, le système lui demande de changer de mot de passe afin
      qu'il soit le seul à connaître ceui-ci. Une fois le mot de passe
      validé, l'utilisateur est connecté. Il voit alors à gauche l'arborescence qui lui permet de voir les documents de
      chaque mois. Par défaut, les documents sont de type <b>Achats</b>
      , <b>Ventes</b> ou<b>Banque</b>.

    </p>
    <h2>Comment le client envoie-t-il ses documents?</h2>
    <p>Le client dispose de trois (3) moyens pour envoyer des documents dans son espace et donc les partager avec son
      expert comptable.</p>
    <h3>1. Depuis l'application VUSADOC web sur laquelle vous êtes en ce moment</h3>
    <p>Le client peut importer un ensemble de documents en cliquant sur le bouton "Importer".</p>
    <img src="/assets/images/vusa-import.png" style="max-width:100%;height:auto" />
  </div>

  <div fxFlex="66.6" class="p-2">
    <h3>2. Depuis l'application VUSADOC mobile </h3>
    <p>Lorsque le client se connecte, il voit sur sa page d'accueil un QR Code. Celui-ci lui permet de s'authentifier
      sur l'application VUSADOC mobile qu'il aura déjà téléchargé depuis l'App Store pour les Iphones
      ou Play Store pour les téléphones Android. </p>
  </div>
  <div fxFlex="33.3" class="p-2">
    <img src="/assets/images/vusa-mobile.png" style="max-width:100%;height:auto" />
  </div>

  <h3>3. Depuis VUSADOC mail</h3>
  <p>Quand le client reçoit un document par mail et qu'il veut l'envoyer dans son espace VUSADOC, il a juste à le
    transférer à l'adresse mail de VUSADOC propre à chaque cabinet. Cette adresse est souvent de la forme
    votreentreprise@vusadoc.fr.
    Cette adresse mail lui est envoyée en même temps que les indications nécessaires dans le message l'invitant à se
    connecter à son compte.
  </p>
  <div fxFlex="66.6" class="p-2" *ngIf="isAdmin || isManager">
    <h2>Le plan comptable fournisseur</h2>
    <p>Le plan comptable fournisseur est nécessaire pour améner certaines informations de la facture: </p>
    <ul>
      <li>Numéro compte</li>
      <li>Numéro compte HT</li>
    </ul>
    <p>D'autres informations sont aussi configurées dans cette liste comme par exemple la référence de la facture, le
      montant HT, le montant TTC, la montant de la TVA
    </p>
  </div>

</div>