
export class Utilisateur{
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    enabled: boolean;
    email: string;
    created: Date;
    updated: Date;
    enterprise: string;
    credentials: any[];
    constructor(id: string,
        firstName: string,
        lastName: string,
        username: string,
        enabled: boolean,
        email: string,
        created?: Date,
        enterprise?: string,
        updated?: Date){
            this.id=id;
            this.firstName=firstName;
            this.lastName=lastName;
            this.username=username;
            this.enabled=enabled;
            this.email=email;
            this.created=created;
            this.updated=updated;
            this.credentials = [];
            this.enterprise=enterprise;
        }
}