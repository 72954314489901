import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Document, NewDocument } from "../model/document";
import { Messages } from '../model/chat.model';
@Injectable({
    providedIn: 'root'
  })
export class DocumentService {

    constructor(private http: HttpClient ){

    }
    update(document : Document):Observable<any>{
       return this.http.put(environment.backendUrl+environment.document+document.documentuniqueid+'/update/',document);
    }
    create(document : NewDocument):Observable<Document>{
      return this.http.post<Document>(environment.backendUrl+environment.documentcreate,document);
   }

   delete(docId: String) :Observable<any>{
      return this.http.put(environment.backendUrl+environment.documentdelete,docId);
  }

  journal(docId: String) :Observable<any>{
   return this.http.put(environment.backendUrl+environment.documentJournal,docId);
  }
   /**
    * Recupération de la liste des messages d'un document
    * @param idDocument 
    * @returns 
    */
   getMessagesByIdDocument(idDocument: string) {
      return this.http.get(`${environment.backendUrl}${environment.documentMessage}/${idDocument}`);
   }
   /**
    * Creation de message
    * @param message 
    * @returns 
    */
   createMessage(message: Messages) {
      return this.http.post(`${environment.backendUrl}${environment.documentMessageCreate}`,message);
   }
}