import { Component, OnInit } from '@angular/core';
import {DossierService} from '../../services/dossier.service';
import { Dossier } from '../../model/dossier';
import { MetaData } from '../../model/metadata';
import { RequestService } from '../../services/request.service';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.scss'],
  providers: [NgbDropdownConfig] 
})
export class LeftSideBarComponent implements OnInit {
  listDossiersLev1: Dossier[];
  listDossiersLev2: Dossier[];
  listDossiersLev3: Dossier[];
  listDossiersLev4: Dossier[];
  listMetadataLev1: MetaData[];
  listMetadataLev2: MetaData[];
  listMetadataLev3: MetaData[];
  listMetadataLev4: MetaData[];
  listMetadata: MetaData[];
  ope: string = "=";
  operators: string[];
  valeur: number;valeur1: number;valeur2: number;
  valeur3: number;
  metaData: string[]=["ABCFD","ABEDF","ACV"];
  vMetaData: string;metaIndex: number;
  constructor(private dossierService: DossierService,
    private authService:AuthService,
    private spinnerService: NgxSpinnerService,
    private requestService: RequestService,config: NgbDropdownConfig) {
      config.placement = 'top-left';
      config.autoClose = false;
      }
  isopen: boolean;
  ngOnInit() {
    this.isopen = false;
    
    this.dossierService.getDossiersByParent(0,this.authService.user).subscribe(resp=>{this.listDossiersLev1=resp;console.log(resp)});
   // this.listMetadataLev1 = this.dossierService.getMetadataByParent(1);
    //this.listMetadata = this.dossierService.getMetadataByParent(0);
    //this.listMetadata = this.listMetadataLev1;
   this.operators=["=","<>","LIKE"];
  }
   openNav() {
    document.getElementById("mySidepanel").style.width = (this.isopen)?"15px":"250px";
    this.isopen=!this.isopen;
  }
  setDossiersLev2(id: any){
    this.dossierService.currentDossierId = id;
    this.spinnerService.show();
    this.dossierService.getDossiersByParent(id,this.authService.user).subscribe(resp=>{this.listDossiersLev2=resp;});
   this.dossierService.getMetadataByParent(id).subscribe(met=>{ this.spinnerService.hide();this.listMetadata=met},
   error=>{this.spinnerService.hide();});
   // this.listMetadata = this.listMetadataLev2;
  }
  setDossiersLev3(id: any){
    this.dossierService.currentDossierId = id;
    this.spinnerService.show();
    this.dossierService.getDossiersByParent(id,this.authService.user).subscribe(resp => {this.listDossiersLev3=resp;});
    this.dossierService.getMetadataByParent(id).subscribe(met=>{this.spinnerService.hide();this.listMetadata=met;},
    error=>{this.spinnerService.hide();});
    //this.listMetadata = this.listMetadataLev3;
   
  }
  setDossiersLev4(id: any){
    this.dossierService.currentDossierId = id;
    this.spinnerService.show();
    this.dossierService.getDossiersByParent(id,this.authService.user).subscribe(resp=>{this.listDossiersLev4=resp;});
    this.dossierService.getMetadataByParent(id).subscribe(met=>{this.spinnerService.hide();this.listMetadata=met;},error=>{this.spinnerService.hide();});
    //this.listMetadata = this.listMetadataLev4;
  }
  setOpe(ope: string){
    this.ope=ope;
  }
  setRequest(){
    this.requestService.addRequestString(this.listMetadata[this.metaIndex].key," OR ",this.vMetaData,this.ope,this.listMetadata[this.metaIndex].field);
    console.log(this.requestService.requestValue);
  }
}
