import { Injectable } from "@angular/core";
import { Dossier } from "../model/dossier";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { Property } from "../model/property";
import { AuthService } from "./auth.service";
import { suppressDeprecationWarnings } from "moment";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable()
export class PropertyService extends AuthService{
    listProperties: Property[]=[];
    constructor(public http: HttpClient,
        public oauthService: OAuthService){
        super(oauthService,http);
    }
    /**
     * 
     * @param doss 
     */
    public getListeProperty(doss: Dossier): Observable<Property[]>{
        return this.http.get<Property[]>(environment.backendUrl+"dossier/"+doss.dossierId+"/properties/",this.httpOptions);
    }

    /**
     * Get All properties
     */
    getAll() {
        return  this.http.get<Property[]>(`${environment.backendUrl}property/all`,this.httpOptions);
    }
    /**
     * Save property
     * @param property 
     */
    saveProperty(property: Property) {
        return  this.http.post<Property>(`${environment.backendUrl}property/save`, property,this.httpOptions);
    }
}