import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from './../../services/breadcrumb.service';
import { Bread } from '../../model/bread';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  items: Bread[];
  current: Bread = new Bread("", "", "", []);
  constructor(private br: BreadcrumbService) { }

  ngOnInit() {
    this.items = this.br.items;
    this.current = this.items[this.items.length - 1];
  }
  ngOnDestroy() { }

}
