import { Component, OnInit } from '@angular/core';
import { Dossier } from '../../shared/model/dossier';
import { DossierService } from '../../shared/services/dossier.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AdminModifDossComponent } from '../../shared/components/admin-modif-doss/admin-modif-doss.component';
import { BreadcrumbService } from '../../shared/services/breadcrumb.service';
import { Bread } from '../../shared/model/bread';
import { AuthService } from '../../shared/services/auth.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { environment } from 'src/environments/environment';
import { DataUploaderComponent } from 'src/app/shared/data-uploader/data-uploader.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dossiers: Dossier[];
  isAdmin: boolean;
  isManager: boolean;
  isUser: boolean;

  elementType = NgxQrcodeElementTypes.CANVAS;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value: string = "";//'{"baseUrl":"' + environment.baseUrl+  '","user":' + JSON.stringify(this.authService.user) + '}';

  constructor(private dossierService: DossierService,
    public dialog: MatDialog,
    private authService: AuthService,
    private toastrService: ToastrService, private br: BreadcrumbService) { }

  ngOnInit(): void {
    this.isAdmin = this.authService.user.roles.indexOf("admin") > -1;
    this.isManager = this.authService.user.roles.indexOf("manager") > -1
    this.isUser = this.authService.user.roles.indexOf("user") > -1
    if (this.isAdmin || this.isManager) {
      this.getAllDossiers()
    }else{
        this.getDossiers()
    }

    this.br.items.splice(0, this.br.items.length);
    this.br.items.push(new Bread("0", "Accueil", "#",[]));
  }

  /**
   * Recuperation de la liste des dossiers
   */
  getAllDossiers() {
    this.dossierService.getDossiersByParent(0, this.authService.user).subscribe(
      doss => this.dossiers = doss
);
  }
   /**
   * Recuperation de la liste des dossiers de l'utilisateur non admin conecté
   */
    getDossiers() {
      this.dossierService.getDossiersByParentSide(0, this.authService.user).subscribe(
        doss => { this.dossiers = doss; 
         this.value = '{"baseUrl":"' + environment.baseUrl+ '","compte":"'+ doss[0].children[0].name + '","user":' + JSON.stringify(this.authService.user) + '}';
         console.log(this.value);
        });
    }
  uploadFile(dos: Dossier, e) {
    let dialogRef = this.dialog.open(DataUploaderComponent, {
      data: environment.backendUrl + "dossier/" + dos.dossierEncoded + "/api/create-dossier"
    });
  }
  openModal(dos: Dossier) {
    var nouvdoss = new Dossier(null, '', '', '', dos.dossierId, []);
    let dialogRef = this.dialog.open(AdminModifDossComponent, {
      width: '512px',
      data: nouvdoss //A revoir le dossier est undifened
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.getAllDossiers();
      }
    });
  }
}


