<div fxLayout="row" fxLayoutAlign="space-between" style="height: 40px;">
    <h2 style="text-align: center;">Glisser / déposer ou parcourir les fichiers</h2>
    <div class="d-flex justify-content-end mb-5" style="margin-top: -5px;">
        <button mat-icon-button class="close-button " [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="black">close</mat-icon>
        </button>
    </div>
</div>

<ng-uppy 
[config]="settings"
(onFileUpload)="onFileUpload($event)" 
(uploadResult)="onUploadResult($event)">
</ng-uppy>
