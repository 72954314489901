<div class="d-flex justify-content-end mb-5">
  <button mat-icon-button class="close-button " [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="black">close</mat-icon>
  </button>
</div>

<div class="container">

  <div class="row">
    <div class="col">
<div class="header">aperçu</div>
      <div id="dwtcontrolContainer"></div>
      <div class="mat-paginator-range-actions">
        <button mat-icon-button="" type="button" (click)="first()"
          class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-first mat-icon-button mat-button-base ng-star-inserted"
          aria-label="First page">
          <span class="mat-button-wrapper">
            <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
              <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
            </svg></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
          <span class="mat-button-focus-overlay"></span>
        </button>
        <!---->
        <!---->
        <button mat-icon-button="" type="button" (click)="previous()"
          class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-previous mat-icon-button mat-button-base"
          aria-label="Previous page">
          <span class="mat-button-wrapper">
            <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
          </span>

          <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
          <span class="mat-button-focus-overlay"></span>
        </button>
        <!---->

        <div class="mat-paginator-range-label"> {{currentImageIndex}}/{{totalImages}} </div>


        <button mat-icon-button="" type="button" (click)="next()"
          class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base"
          aria-label="Next page">
          <span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            </svg>
          </span>
          <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
          <span class="mat-button-focus-overlay"></span>
        </button>
        <!---->
        <button mat-icon-button="" type="button" (click)="last()"
          class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-last mat-icon-button mat-button-base ng-star-inserted"
          aria-label="Last page">
          <span class="mat-button-wrapper">
            <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
              <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
            </svg></span>
          <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
          <span class="mat-button-focus-overlay"></span>
        </button>
      </div>
     



    </div>


    <div class="col">
      <div class="header">paramètres</div>
      <form [formGroup]="scanForm">
        <table>
          <tr><th> <label for='name'><b>Choisir scanner </b>&nbsp;&nbsp;</label></th><td> <select id="sources"></select><br /></td></tr>
          <tr><th> <label for='name'><b>Résolution (DPI)</b>&nbsp;&nbsp;</label></th><td><select formControlName="resolution">
            <option value="O">100</option>
            <option value="1">200</option>
            <option value="2">300</option>
          </select></td></tr>
          <tr><th> <label for='name'><b>Couleur </b>&nbsp;&nbsp;</label></th><td>        <select formControlName="pixeltype">
            <option value="O" value="0">Noir/Blanc</option>
            <option value="1">Gris</option>
            <option value="2">Couleur</option>
          </select></td></tr>
          <tr><th> <label for='name'><b>Recto/Verso </b>&nbsp;&nbsp;</label></th><td><select formControlName="duplex">
            <option value="O">Non</option>
            <option value="1">Oui</option>
          </select></tr>
          <tr><td colspan="2">   <button (click)="acquireImage()" class="btn btn-primary">Numériser</button>&nbsp;&nbsp;&nbsp;
            <button [disabled]="disableSave" (click)="save() " class="btn btn-primary"> Enregistrer</button></td></tr>
        </table>

      </form>

      <br /><br /><br />
      <div class="progress form-group" *ngIf="progress > 0">
        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
        </div>
      </div>
    </div>

  </div>
</div>