<div class="container">
    <div class="d-flex justify-content-end">
        <button mat-icon-button class="close-button " [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="black">close</mat-icon>
        </button>
    </div>
    
        <div class="row">
            <div class="col" class="colViewer">
     
                <div *ngIf="viewer === 'pdf'">
                    <ngx-doc-viewer [url]="fileUrl" viewer="pdf" style="width:100%;height:80vh"></ngx-doc-viewer>
                </div>
    
            </div>
    
        </div>
    </div>
