<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col-lg-6">
        <div class="page-header-left">
          <!--<h3>Search</h3>-->
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/'">
                <app-feather-icons [icon]="'home'"></app-feather-icons>
              </a></li>

            <li class="breadcrumb-item" *ngFor="let item of items;" [routerLink]="'/user/search/'+item.code">
              {{item.name}}
            </li>
          </ol>
         <!-- <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let child of current.children"
              [routerLink]="'/user/search/'+child.code">
              {{child.name}}</li>
          </ol>-->

        </div>
      </div>
    </div>
  </div>
</div>