export class RequestItem{
    queryString: string;
    userString:string;
    logOpe: string;
    constructor(   queryString: string,
        userString:string,
        logOpe: string){
            this.queryString = queryString;
            this.userString = userString;
            this.logOpe = logOpe;
    }
}