<!--error-403 start-->
<div class="error-wrapper">
    <div class="container">
        <img class="img-100" src="assets/images/other-images/sad.png" alt="">
        <div class="error-heading">
            <img src="assets/images/cloud-bg-1.png" class="cloud-first" alt="">
            <h2 class="headline font-primary">403</h2>
            <img src="assets/images/cloud-bg-2.png" class="cloud-second" alt="">
        </div>
        <div class="col-md-8 offset-md-2">
            <p class="sub-content">
                Vous n'avez pas les droits nécessaires pour accèder à cette page.
            </p>
        </div>
        <div class="">
            <a [routerLink]="'/user/home'" class="btn btn-primary-gradien btn-lg">Retour à l'accueil</a>
        </div>
    </div>
</div>
<!--error-403 end-->