import { Component, OnInit } from '@angular/core';
import { ReferenceService } from '../../shared/services/reference.service';
import { Reference } from '../../shared/model/reference';
import { Observable } from 'rxjs';
import { LevelEnum } from '../utils/level.enum';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { elementAt } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataUploaderComponent } from '../../shared/data-uploader/data-uploader.component';
import { environment } from 'src/environments/environment';
export interface WorkflowElement {
  fromStatus: string;
  action: string;
  toStatus: string;
}
@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  newChild: number = -1;
  referenceValue: string = "";
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  references: Reference[];
  subcription: any;
  public errorMessage: string;
  constructor(private referenceService: ReferenceService, private toastrService: ToastrService,public dialog: MatDialog) { }

  displayedColumns: string[] = ["fromStatus", "action", "toStatus"];
  dataSource = [];
  workflowForm: FormGroup;

  ngOnInit(): void {
    this.getListeReference();
    this.initForm();

  }

  initForm() {
    this.workflowForm = new FormGroup({
      fromStatus: new FormControl(""),
      action: new FormControl(""),
      toStatus: new FormControl(""),
    });
  }

  getListeReference() {
    this.referenceService.getListReference(LevelEnum.NIVEAU_0).subscribe(
      references => {
        this.references = references
        /*this.references.forEach(elt=>this.getReferenceChildren(elt.referenceId).subscribe(
          children=>{elt.children=children;
           console.log("References", this.references);}
        ));
        */
      },
      err => this.errorMessage = err,

    );
  }
  getReferenceChildren(id: number) {
    return this.referenceService.getReferenceChildren(id);
  }
  onClick(ref: Reference) {

  }

  /**
   * create a level 0 list entry
   */
  create(value: string) {


  }
  addTrans() {
    //this.dataSource.push({})
    this.dataSource.push({
      fromStatus: this.workflowForm.value["fromStatus"],
      action: this.workflowForm.value["action"],
      toStatus: this.workflowForm.value["toStatus"]
    });

  }
  /**
   * add a new child to ref
   * @param ref : the ref where e add a child
   */
  addChild(event: MatChipInputEvent, ref: Reference): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      ref.children.push(new Reference(value.trim(), ref.level + 1, ref.referenceId));
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  add(): void {
    const index = this.references.indexOf(this.references.filter((elemnt) => elementAt.name == this.referenceValue)[0]);
    if (index < 0) {
      this.referenceService.createReference(new Reference(this.referenceValue, 0)).subscribe(
        res => {
          this.toastrService.success("La liste est sauvegardée avec succès.");
          this.references.push(res);
          this.referenceValue = "";
        },
        err => {
          this.toastrService.error("Une erreur s'est produite lors de l'enregistrement de la liste.", err);
        }
      )
    }
  }

  removeChild(ref: Reference, child: Reference): void {
    const index = ref.children.indexOf(child);

    if (index >= 0) {
      this.subcription = this.referenceService.removeReference(child.referenceId).subscribe(
        res => {
          if (index >= 0) {
            ref.children.splice(index, 1);
          }
          this.toastrService.success(res, "OK!.")
        },
        err => {
          this.toastrService.error(err, "KO!");
        }
      )

    }
  }
  remove(ref: Reference): void {
    const index = this.references.indexOf(ref);

    this.referenceService.removeReference(ref.referenceId).subscribe(
      res => {
        if (index >= 0) {
          this.references.splice(index, 1);
          this.toastrService.success("OK!.");
        }
      },

      err => {
        this.toastrService.success("KO!", "Une erreur s'est produite ors de la suppression");
      }
    )
  }
  save(ref: Reference) {
    console.log("Ref", ref);
    this.referenceService.createReference(ref).subscribe(
      refe => {
        this.toastrService.success("La liste est sauvegardée avec succès.");
        console.log("Refe", refe);
        ref.children = refe.children;
        ref.displayMode = refe.displayMode;
        ref.workflow = refe.workflow;
      },
      err => {
        this.toastrService.success("Une erreur s'est produite lors de l'enregistrement de la liste.", err);
      }
    )
  }

  uploadFile(ref: Reference, e) {
    let dialogRef = this.dialog.open(DataUploaderComponent, {
      data: environment.backendUrl + "reference/" + "/api/"+ ref.referenceId
    });
  }

}
