import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Bread } from './../model/bread';
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  constructor(private http: HttpClient) {

  }
  items: Bread[] = [];
  getBreadcrumb(index: String): Observable<Bread[]> {
    return this.http.get<Bread[]>(environment.backendUrl + environment.dossier + 'scrumb/' + index);
  }

}