<mat-form-field appearance="fill">
  <input matInput [(ngModel)]="referenceValue" placeholder="Nouvelle liste...">
</mat-form-field>

<button mat-raised-button color="primary" (click)="add()">Ajouter</button>

<mat-accordion>

  <mat-expansion-panel *ngFor="let ref of references;let index=index " (opened)="panelOpenState = true"
    (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ref.name}}
      </mat-panel-title>
      <mat-panel-description>
        {{ref.description}}
        <mat-icon matChipRemove *ngIf="ref.children.length===0" class="ml-auto" (click)=remove(ref)>cancel</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>


    <mat-form-field>

      <mat-chip-list #chipList>
        <mat-chip *ngFor="let child of ref.children " [selectable]="selectable" [removable]="removable"
          [color]="Secondary" (removed)="removeChild(ref,child)">
          {{child.name}}
          <mat-icon matChipRemove *ngIf="removable" class="ml-auto">cancel</mat-icon>
        </mat-chip>

        <input placeholder="nouvel élément..." [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addChild($event,ref)">
      </mat-chip-list>


    </mat-form-field>
    <hr />
    <mat-radio-group aria-label="Select an option" [(ngModel)]="ref.displayMode">
      <mat-radio-button [value]="'radio'">Radio</mat-radio-button>&nbsp;&nbsp;
      <mat-radio-button [value]="'liste'">Liste</mat-radio-button>
    </mat-radio-group>
    &nbsp;&nbsp;
    <mat-checkbox [value]="ref.workflow" [(ngModel)]="ref.workflow"> Workflow</mat-checkbox>&nbsp;&nbsp;

    <button mat-raised-button (click)="save(ref)">Enregistrer</button>
    <button mat-raised-button (click)="remove(ref)" *ngIf="ref.children.length==0">Supprimer</button>
    <button mat-raised-button (click)="uploadFile(ref,$event)" *ngIf="ref.referenceId!=null">Importer</button>

    


    <hr>


    <div class="container" style="width:750px;display: inline-block;" *ngIf="ref.workflow">
      <form [formGroup]="workflowForm">
        <mat-form-field appearance="fill">
          <mat-label>Etat de départ</mat-label>
          <mat-select formControlName="fromStatus">
            <mat-option *ngFor="let child of ref.children " [value]="child.name">{{child.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Action</mat-label>
          <input matInput formControlName="action">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Etat d'arrivée</mat-label>
          <mat-select formControlName="toStatus">
            <mat-option *ngFor="let child of ref.children " [value]="child.name">{{child.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="addTrans()">Ajouter</button>

      </form>


      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width:100%">

        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="fromStatus">
          <th mat-header-cell *matHeaderCellDef> Etat de départ</th>
          <td mat-cell *matCellDef="let element"> {{element.fromStatus}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element"> {{element.action}}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="toStatus">
          <th mat-header-cell *matHeaderCellDef> Etat d'arrivée </th>
          <td mat-cell *matCellDef="let element">{{element.toStatus}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>


  </mat-expansion-panel>

</mat-accordion>