import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propFilter'
})
export class PropFilterPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => (item[filter['keyToFilter']]?.toLowerCase().indexOf(filter['key']?.toLowerCase()) !== -1)|| filter['key']?.toLowerCase()===undefined);
    }
}