<div class="container-fluid">
  <div class="row">
    <div class="col-12" style="padding: 0;">
      <mat-card>
        <mat-card-header>

          <div class="row">
            <div class="form-group col-2" *ngFor="let prop of document.properties">
              <div class="example-full-width" *ngIf="prop.property.type=='number' || prop.property.key=='N Pièce'">
                <mat-label>{{prop.property.key}}</mat-label>
                <input matInput [value]="prop.value" (click)="operationForm?.get('montant').setValue(prop.value)"
                  readonly>
              </div>
            </div>
          </div>


          <button mat-icon-button class="float-right btn-align-right" matTooltip="Fermer la modale"
            (click)="closeModale()" aria-label="Example icon-button with a heart icon">
            <mat-icon>close</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>


          <form [formGroup]="operationForm" class="container-fluid" (submit)="addOp()"
            style="margin-top: 20px; margin-bottom: 20px;">
            <div class="row">
              <div class="form-group col-4">
                <mat-form-field appearance="fill">
                  <mat-label>Journal <span class="field-required"></span></mat-label>
                  <mat-select formControlName="journal" required>
                    <mat-option *ngFor="let ref of journalOptions | async" [value]="ref.name">
                      {{ref?.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="operationForm?.get('journal').hasError('required')">Champ obligatoire</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group col-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Date</mat-label>
                  <input matInput formControlName="date" type="date">
                </mat-form-field>
              </div>

              <div class="form-group col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Numéro de compte</mat-label>
                  <input type="text" placeholder="Numéro de compte" aria-label="NumeroCompte" matInput
                    formControlName="numCompte" [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of compteOptions | async" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="operationForm?.get('numCompte').hasError('required')">Champ obligatoire</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-4">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Numéro de compte secondaire</mat-label>
                  <input type="text" placeholder="Numéro de compte" aria-label="NumeroCompteSec" matInput
                    formControlName="numCompteSec" [matAutocomplete]="autoSup">
                  <mat-autocomplete #autoSup="matAutocomplete">
                    <mat-option *ngFor="let option of compteSupOptions | async" [value]="option.name">
                      {{option.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="form-group col-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Description</mat-label>
                  <input matInput formControlName="libel">
                </mat-form-field>
              </div>
              <div class="form-group col-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Montant</mat-label>
                  <input matInput formControlName="montant" type="number">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-4">
                <mat-form-field appearance="fill">
                  <mat-label>Sens <span class="field-required"></span></mat-label>
                  <mat-select formControlName="sens" required>
                    <mat-option *ngFor="let sens of sensOptions" [value]="sens">
                      {{sens}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="operationForm?.get('sens').hasError('required')">Champ obligatoire</mat-error>
                </mat-form-field>
              </div>

            </div>


            <div class="row">
              <div class="col-2">
                <button mat-raised-button class="btn btn-secondary" type="button" (click)="reset()">Nouveau</button>
              </div>
              <div class="col-2">
                <button [disabled]="!operationForm?.valid" mat-raised-button class="btn btn-secondary"
                  type="submit">Ajouter</button>
              </div>
              <div class="col-2">
                <button mat-raised-button (click)="account()" type="button"
                  class="btn btn-secondary">Comptabiliser</button>
              </div>
            </div>
          </form>



          <!--/buttons-->
          <!--/buttons-->
          <div class="mat-elevation-z8 ">
            <!-- <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-alpine" [rowData]="operationList"
              [columnDefs]="displayedColumns" (cellClicked)="onCellClicked($event)" (gridReady)="onGridReady($event)"
              [overlayLoadingTemplate]="loadingTemplate" [overlayNoRowsTemplate]="noRowsTemplate">
            </ag-grid-angular>-->
          </div>
        </mat-card-content>
        <mat-card-footer>

        </mat-card-footer>
      </mat-card>

    </div>
  </div>
</div>