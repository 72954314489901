import { Chart } from "chart.js";

export class Rapport{
    compName: string;
    axeValue: number;
    constructor(compName: string, axeValue:number){
        this.compName=compName;
        this.axeValue=axeValue;
    }
}
interface ChartInit {
     initBar();
     execute(selectedComp,selectedAxe:any,dataSource:any);
     show();
}
export class BarChart{
    spinner: boolean;
    showchart: boolean;
    barChartOptions: any;
    barChartData:any;
    barChartLabels = [];
    barChartLegend: boolean;
    barChartType: string;
    showChart: boolean;
    arrayValues: Rapport[]=[];   
    arrValues: Rapport[]=[];
    selectedAxe:any;
   constructor(){
    this.showChart=false;
    this.barChartType="bar";
    this.barChartOptions = {
        scaleShowVerticalLines: false,
        responsive: true,
        scales: {
          yAxes: [{
              ticks: {
                  beginAtZero: true
              }
          }]
      }
      };
      this.barChartData = [
        {data: [], label: '',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      
      },
    
      ];
   }
    public init(spin:boolean){
        this.spinner=spin;
        this.showChart=false;
        this.barChartLegend=false;
        this.barChartData[0]["data"].splice(0,this.barChartData[0]["data"].length);
        this.arrayValues=[].splice(0,this.arrayValues.length);
        this.arrValues=[].splice(0,this.arrValues.length);
        this.barChartLabels.splice(0,this.barChartLabels.length);
    }
    private show(){

    }

    public execute (selectedComp:any,dataSource:any){
      console.log("this is the dataSource",dataSource);
        var rapp: Rapport;
        this.barChartData[0]["label"]=this.selectedAxe["name"];
        this.init(true);
          selectedComp['list']['listValues'].forEach(element => {
          rapp=new Rapport(element.value,0);
          this.arrValues.push(JSON.parse('{"name":"'+rapp.compName+'","value":0,'+'"prop":"'+selectedComp['prop']+'"}'));
          //this.barChartLabels.push(rapp.compName);
          });
          this.arrValues.forEach(element => {
            element["value"]=dataSource?.data?.filter((item)=>{
             // return item[element["prop"]][0].value.toLowerCase().indexOf(element['name'].toLowerCase())>=0;
              return item[element["prop"]][0].value.toLowerCase()==element['name'].toLowerCase();
            })
            .reduce((acc, line) => acc + +line[this.selectedAxe["prop"]][0].value, 0);
          if( element["value"]>0){
            this.barChartData[0]["data"].push(element["value"]);
            this.barChartLabels.push(element["name"]);
            this.arrayValues.push(element);
          }
          });
         
          setTimeout(()=>{                           // <<<---using ()=> syntax
            this.showChart=true;
            this.spinner=false;
        }, 1000);
      }

}
export class DoughnutChart{
    showDoughnut: boolean;
    arrayValues: Rapport[]=[];
    arrValues: Rapport[]=[];
    selectedAxe:any;
    spinner: boolean;
    showChart: boolean;
    doughnutChartData: { data: any[]; label: string; backgroundColor: string[]; borderColor: string[]; }[];
    doughnutChartLegend: boolean;
    doughnutChartLabels: any=[];
    doughnutChartType: string;
    
    constructor(){
        this.doughnutChartData = [
            {data: [], label: '',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          
          },
        
          ];
    }
    public init(spin:boolean){
        this.spinner=spin;
        this.showChart=false;
        this.doughnutChartLegend=false;
        this.doughnutChartData[0]["data"].splice(0,this.doughnutChartData[0]["data"].length);
        this.arrayValues=[].splice(0,this.arrayValues.length);
        this.arrValues=[].splice(0,this.arrValues.length);
        this.doughnutChartLabels.splice(0,this.doughnutChartLabels.length);
        this.doughnutChartType="doughnut";
    }
    public execute (selectedComp:any,dataSource:any){
        var rapp: Rapport;
        var nbr=0;
        this.doughnutChartData[0]["label"]=this.selectedAxe["name"];
        this.init(true);
          selectedComp['list']['listValues'].forEach(element => {
          rapp=new Rapport(element.value,0);
          this.arrValues.push(JSON.parse('{"name":"'+rapp.compName+'","value":0,'+'"prop":"'+selectedComp['prop']+'"}'));
         
          });
          this.arrValues.forEach(element => {
            element["value"]=dataSource?.data?.filter((item)=>{
             // return item[element["prop"]][0].value.toLowerCase().indexOf(element['name'].toLowerCase())>=0;
              return item[element["prop"]][0].value.toLowerCase()==element['name'].toLowerCase();
            })
            .reduce((acc, line) => acc + +line[this.selectedAxe["prop"]][0].value, 0);

            if( element["value"]>0){
              this.doughnutChartData[0]["data"].push(element["value"]);
              this.doughnutChartLabels.push(element["name"]);
              this.arrayValues.push(element);
            }
            
          });
         
          setTimeout(()=>{                           // <<<---using ()=> syntax
            this.showChart=true;
            this.spinner=false;
        }, 1000);
    }
    
}
export class PieChart{
    pieChartData: { data: any[]; label: string; backgroundColor: string[]; borderColor: string[]; }[];
    pieChartLegend: boolean;
    pieChartLabels: any=[]=[];
    pieChartType: string;
    spinner: boolean;
    showChart: boolean;
    arrayValues: any[]=[];
    arrValues: any[]=[];
   
    selectedAxe: any;
    constructor(){
        this.pieChartData = [
            {data: [], label: '',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          
          },
        
          ];
    }
    public init(spin:boolean){
        this.spinner=spin;
        this.showChart=false;
        this.pieChartLegend=false;
        this.pieChartData[0]["data"].splice(0,this.pieChartData[0]["data"].length);
        this.arrayValues=[].splice(0,this.arrayValues.length);
        this.arrValues=[].splice(0,this.arrValues.length);
        this.pieChartLabels.splice(0,this.pieChartLabels.length);
        this.pieChartType="pie";
    }
    public execute (selectedComp:any,dataSource:any){
        var rapp: Rapport;
        this.pieChartData[0]["label"]=this.selectedAxe["name"];
        this.init(true);
          selectedComp['list']['listValues'].forEach(element => {
          rapp=new Rapport(element.value,0);
          this.arrValues.push(JSON.parse('{"name":"'+rapp.compName+'","value":0,'+'"prop":"'+selectedComp['prop']+'"}'));
         
          });
          this.arrValues.forEach(element => {
            element["value"]=dataSource?.data?.filter((item)=>{
             // return item[element["prop"]][0].value.toLowerCase().indexOf(element['name'].toLowerCase())>=0;
              return item[element["prop"]][0].value.toLowerCase()==element['name'].toLowerCase();
            })
            .reduce((acc, line) => acc + +line[this.selectedAxe["prop"]][0].value, 0);
  
            if( element["value"]>0){
              this.pieChartData[0]["data"].push(element["value"]);
              this.pieChartLabels.push(element["name"]);
              this.arrayValues.push(element);
            }

          });
         
          setTimeout(()=>{                           // <<<---using ()=> syntax
            this.showChart=true;
            this.spinner=false;
        }, 1000);
    }
    
}

export class RadarChart{
    radarChartData: { data: any[]; label: string; backgroundColor: string[]; borderColor: string[]; }[];
    radarChartLegend: boolean;
    radarChartLabels: any=[]=[];
    radarChartType: string;
    spinner: boolean;
    showChart: boolean;
    arrayValues: any[]=[];
    arrValues: any[]=[];
    selectedAxe: any;
    constructor(){
        this.radarChartData = [
            {data: [], label: '',
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
          ],
          
          },
        
          ];
    }
    public init(spin:boolean){
        this.spinner=spin;
        this.showChart=false;
        this.radarChartLegend=false;
        this.radarChartData[0]["data"].splice(0,this.radarChartData[0]["data"].length);
        this.arrayValues=[].splice(0,this.arrayValues.length);
        this.arrValues=[].splice(0,this.arrayValues.length);
        this.radarChartLabels.splice(0,this.radarChartLabels.length);
        this.radarChartType="radar";
    }
    public execute (selectedComp:any,dataSource:any){
        var rapp: Rapport;
        this.radarChartData[0]["label"]=this.selectedAxe["name"];
        this.init(true);
          selectedComp['list']['listValues'].forEach(element => {
          rapp=new Rapport(element.value,0);
          this.arrValues.push(JSON.parse('{"name":"'+rapp.compName+'","value":0,'+'"prop":"'+selectedComp['prop']+'"}'));
         
          });
          this.arrValues.forEach(element => {
            element["value"]=dataSource?.data?.filter((item)=>{
             // return item[element["prop"]][0].value.toLowerCase().indexOf(element['name'].toLowerCase())>=0;
              return item[element["prop"]][0].value.toLowerCase()==element['name'].toLowerCase();
            })
            .reduce((acc, line) => acc + +line[this.selectedAxe["prop"]][0].value, 0);
           
            if( element["value"]>0){
              this.radarChartData[0]["data"].push(element["value"]);
              this.radarChartLabels.push(element["name"]);
              this.arrayValues.push(element);
            }
          });
         
          setTimeout(()=>{                           // <<<---using ()=> syntax
            this.showChart=true;
            this.spinner=false;
        }, 1000);
    }
}