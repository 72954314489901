<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col call-chat-body">
      <div class="card">
        <div class="card-body p-0">
          <div class="row chat-box">
            <!-- Chat right side start-->
            <div class="col pr-0 chat-right-aside">
              <!-- chat start-->
              <div class="chat">
                <!-- chat-header start-->
                <div class="chat-header clearfix">
                  <!-- <img class="rounded-circle" [src]="chatUser?.profile" alt=""> -->
                  <div class="about">
                    <!-- <div class="name">{{chatUser?.name}}  <span class="font-primary f-12"
                        *ngIf="chatUser?.typing">Typing...</span></div>
                    <div class="status digits">{{chatUser?.seen}}</div> -->
                  </div>
                  <!-- <ul class="list-inline float-left float-sm-right chat-menu-icons">
                    <li class="list-inline-item"><a href="javascript:void(0)"><i class="icon-search"></i></a></li>
                    <li class="list-inline-item"><a href="javascript:void(0)"><i class="icon-clip"></i></a></li>
                    <li class="list-inline-item"><a href="javascript:void(0)"><i class="icon-headphone-alt"></i></a>
                    </li>
                    <li class="list-inline-item"><a href="javascript:void(0)"><i class="icon-video-camera"></i></a></li>
                    <li class="list-inline-item toogle-bar"><a href="javascript:void(0)"><i class="icon-menu"></i></a>
                    </li>
                  </ul> -->
                </div>
                <!-- chat-header end-->
                <div class="chat-history chat-msg-box custom-scrollbar">
                  <ul>
                    <li *ngFor="let chat of chats?.message" [ngClass]="{'clearfix': chat.sender == userAuth.email }" style="margin-bottom: 10px;">
                      <div class="message my-message mb-0"  *ngIf="chat.sender != userAuth.email; else myChat">
                        <!-- <img class="rounded-circle float-left chat-user-img img-30" [src]="chatUser?.profile" alt=""> -->
                        <div class="message-data text-right">
                          <span class="message-data-time">{{chat?.time}}</span>
                        </div> {{chat?.text}}
                      </div>
                      <ng-template #myChat>
                        <div class="message other-message pull-right">
                          <!-- <img class="rounded-circle float-right chat-user-img img-30" [src]="profile?.profile" alt=""> -->
                          <div class="message-data">
                            <span class="message-data-time">{{chat?.time}}</span>
                          </div> {{chat?.text}}
                        </div>
                      </ng-template>
                    </li>
                    <ng-container *ngIf="!chats?.message.length">
                      <div class="image-not-found">
                        <div class="d-block start-conversion">
                          <img src="assets/images/start-conversion.jpg">
                        </div>
                      </div>
                    </ng-container>
                  </ul>
                </div>
                <!-- end chat-history-->
                <form #chatForm="ngForm" (ngSubmit)="sendMessage(chatForm)">
                  <div class="chat-message clearfix">
                    <div class="row">
                      <div class="col-xl-12 d-flex">
                        <!-- <div class="smiley-box bg-primary">
                          <div class="picker"><img src="assets/images/smiley.png" alt=""></div>
                        </div> -->
                        <div class="input-group text-box">
                          <input class="form-control input-txt-bx" [class.border-danger]="error" [(ngModel)]="chatText"
                            id="message-to-send" ngModel type="text" name="message" placeholder="Composez un message ......">
                          <div class="input-group-append">
                            <button class="btn btn-primary" (click)="sendMessage(chatForm)" type="button">Envoyer</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <!-- end chat-message-->
                <!-- chat end-->
                <!-- Chat right side ends-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends -->
