<div class="container-fluid">
  <div class="row">
    <div class="col-4">
      <form [formGroup]="objectForm" (ngSubmit)="save()" id="childFormData">
        <!--buttons-->
        <div>
          <label class="btn-action" *ngIf="!edit && !dossier?.close">
            <button class="btn btn-secondary" (click)="modifier()"> Modifier</button>
          </label>
          <label class="btn-action" *ngIf="edit">
            <button class="btn btn-secondary" type="button" (click)="save()" [disabled]="!objectForm.valid" >
              Enregistrer</button>
            <!--
                    onclick="document.getElementById('childFormData').sibmit()" 
                  -->
          </label>

          <label class="btn-action" *ngIf="!edit">
            <button class="btn btn-secondary" type="button" (click)="downloadFile()"> Télécharger</button>
          </label>

          <label class="btn-action" *ngIf="!edit">
            <button class="btn btn-secondary" type="button" (click)="sendFile()" *ngIf=" !dossier?.close"> Envoyer</button>
          </label>
        </div>
        <div>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Données
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class='form-group' *ngFor="let col of columns">
                <label for='name'><b>{{col.name}}</b>&nbsp;&nbsp;</label>

                <span *ngIf="(!edit || col.readonly ) && col.type!='date'"
                  class="form-control">{{curMeta[col.prop][0]["value"]}}</span>
                <span *ngIf="(!edit|| col.readonly)  && col.type=='date'"
                  class="form-control">{{(curMeta[col.prop][0]["value"])?(curMeta[col.prop][0]["value"] |
                  date:"dd/MM/yyyy"):''}} </span>


                <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
                  *ngIf="!col.readonly && edit && col.type=='text'">
                <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="number" class='form-control'
                  *ngIf="!col.readonly && edit && col.type=='number'">
                <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="date" class='form-control'
                  *ngIf="!col.readonly && edit && col.type=='date'">
                <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="time" class='form-control'
                  *ngIf="!col.readonly && edit && col.type=='time'">
                <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="tel" class='form-control'
                  *ngIf="!col.readonly && edit && col.type=='tel'">
                <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
                  *ngIf="!col.readonly && edit && col.type=='email'">


                <select *ngIf="edit && col.type=='list' && col.list.display=='liste'"
                  [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
                  <option *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}</option>
                </select>
                <mat-radio-group *ngIf="edit && col.type=='list' && col.list.display=='radio'"
                  [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
                  <mat-radio-button *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}
                  </mat-radio-button>&nbsp;&nbsp;
                </mat-radio-group>
              </div>
              <!--<app-data-viewer [columns]="columns" (objForm)="save($event)"></app-data-viewer>-->
            </mat-expansion-panel>
           <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngIf="'#Achats#Ventes'.indexOf(dossier.title)>0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Journalisation
                </mat-panel-title>

              </mat-expansion-panel-header>
              <app-operation [operationControl]="operationControl"></app-operation>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Fil de discussions  {{dossier.title}}
                </mat-panel-title>

              </mat-expansion-panel-header>
              <app-chat [docId]="docID"></app-chat>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!--end buttons-->


      </form>


    </div>
    <div class="col-8" style="overflow: auto" >

      <div *ngIf="viewer === 'pdf'">
        <ngx-doc-viewer [url]="fileUrl" viewer="pdf" style="width:100%;height:90vh;"></ngx-doc-viewer>
      </div>
      <div *ngIf="viewer === 'docx'">
        <ngx-doc-viewer [url]="fileUrl" viewer="mammoth" style="width:100%;height:50vh;"></ngx-doc-viewer>
      </div>
      <div *ngIf="!viewer"> <img src="../../../assets/images/loading.gif" alt=""> </div>



      <div class="col-8" *ngIf="viewer === 'error'">
        <form [formGroup]="form" (ngSubmit)="uploadFile()">


          <div *ngIf="sizeError">{{errorMessage }} {{fileSize/1024000}}</div>
          <div class="form-group">
            <!--<input type="file" (change)="uploadFile2($event)">-->
            <input type="file" name="fileInput" (change)="uploadFile2($event)" accept="application/pdf" />

          </div>

                    <!-- Progress Bar -->
                    <div class="progress form-group" *ngIf="progress > 0">
                      <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
                      </div>
                    </div>

          <div class="form-group" style="width:100px">
            <button class="btn btn-primary btn-block btn-lg">Envoyer</button>
          </div>
        </form>
      </div>

    </div>
    <!--
<div class="col-4"><app-timeline></app-timeline></div>-->
  </div>
</div>