import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-data-viewer',
  templateUrl: './data-viewer.component.html',
  styleUrls: ['./data-viewer.component.scss']
})
export class DataViewerComponent implements OnInit {
  @Input() columns;
  @Input() curMeta;
  public objectForm: FormGroup;
  @Output() eventForm : EventEmitter<FormGroup> = new EventEmitter();;
  constructor() { }

  ngOnInit(): void {
    this.objectForm= new FormGroup({});
    var formControl: FormControl;
    // var jsonString: string = '{';
     var sep="";
     var validators: ValidatorFn[]=[];
     for (var i=0;i<this.columns.length;i++){
       validators.splice(0,validators.length);
       if (this.columns[i]["required"]){
         validators.push(Validators.required);
       }
       if (this.columns[i]["hasmin"] ){
         validators.push(Validators.min(this.columns[i]["minvalue"]));
       }
       if (this.columns[i]["hasmax"]){
         validators.push(Validators.max(this.columns[i]["maxvalue"]));
       }
      
      if (this.columns[i]["pattern"]){
         validators.push(Validators.pattern(this.columns[i]["pattern"]));
       }
       if (this.columns[i]["type"]=="email"){
         validators.push(Validators.email);
       }
       formControl = new FormControl(this.curMeta[this.columns[i]["prop"]][0]["value"],validators);
       this.objectForm.addControl(this.columns[i]["prop"]+'_'
       +this.curMeta[this.columns[i]["prop"]][0]["id"],formControl);
     }
  }

  save(){
    this.eventForm.emit(this.objectForm);
  }

}
