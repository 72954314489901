export class PropertyValue{
    id: number;
    value: string;
    propertyId: number;
    constructor(
        id: number,
        value: string,propertyId){
        this.id=id;
        this.value=value;
        this.propertyId=propertyId;
    }
}