import { Injectable } from '@angular/core';
// import { ChatDB } from '../../shared/data/chat/chat';
// import { ChatUsers, chat } from '../../shared/model/chat.model';
import { Observable, Subscriber } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import { chat, ChatUsers, Messages } from '../model/chat.model';
import { ChatDB } from '../data/chat/chat';
import { DocumentService } from './document.service';

var today = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public observer: Subscriber<{}>;
  public chat: any[] = []
  public users: any[] = []

  constructor(private documentService:DocumentService) {
   //this.chat = ChatDB.chat
    this.users = ChatDB.chatUser
  }

  // Get User Data
  public getUsers(): Observable<ChatUsers[]> {
    const users = new Observable(observer => {
      observer.next(this.users);
      observer.complete();
    });
    return <Observable<ChatUsers[]>>users;
  }

  // Get cuurent user
  public getCurrentUser() {
    return this.getUsers().pipe(map(users => {
      return users.find((item) => {
        return item.authenticate === 1;
      });
    }));
  }

  // chat to user
  public chatToUser(id: number) {
    return this.getUsers().pipe(map(users => {
      return users.find((item) => {
        return item.id === id;
      });
    }));
  }

  // Get users chat
  public getUserChat(): Observable<chat[]> {
    console.log("user chart ob", this.chat);
    const chat = new Observable(observer => {
      observer.next(this.chat[0]);
      observer.complete();
    });
    return <Observable<chat[]>>chat;
  }

  // Get chat History
  public getChatHistory(id: string) {
    return this.getUserChat();
    // .pipe(map(users => {
    //   return users.find((item) => {
    //     return item.id === id;
    //   });
    // }));
  }

  getMessages(docId: string, email: string) {
    this.documentService.getMessagesByIdDocument(docId)
    .subscribe(resp => {
      this.chat.push({id: email, message: resp})
    })
  }
  // Send Message to user
  public sendMessage(chat) {
    let message: Messages = {
      sender: chat.sender,
      text: chat.message,
      time: today.toLowerCase(),
      documentuniqueId: chat.docId
    }
    this.documentService.createMessage(message)
      .subscribe(resp => {
       // this.chat.push()
       this.getMessages(chat.docId, chat.sender);
        setTimeout(function () {
          document.querySelector(".chat-history").scrollBy({ top: 200, behavior: 'smooth' });
        }, 310)
      })
    // this.chat.filter(chats => {
    // // if (chats.id == chat.receiver) {
    //     chats.message.push({ sender: chat.sender, time: today.toLowerCase(), text: chat.message })
    //     setTimeout(function () {
    //       document.querySelector(".chat-history").scrollBy({ top: 200, behavior: 'smooth' });
    //     }, 310)
    //     this.responseMessage(chat)
    //  //}
    // })
  }

  public responseMessage(chat) {

    this.chat.filter(chats => {
     if (chats.id == chat.receiver) {
        setTimeout(() => {
          chats.message.push({ sender: chat.receiver, time: today.toLowerCase(), text: 'Hey This is ' + chat.receiver_name + ', Sorry I busy right now, I will text you later' })
        }, 2000);
        setTimeout(function () {
          document.querySelector(".chat-history").scrollBy({ top: 200, behavior: 'smooth' });
        }, 2310)
     }
    })
  }

}
