import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../shared/services/request.service';
import {MatTableDataSource} from '@angular/material/table';
import {  ActivatedRoute, ParamMap } from '@angular/router';
import { DossierService } from '../../shared/services/dossier.service';
import { BreadcrumbService } from '../../shared/services/breadcrumb.service';
import { Bread } from '../../shared/model/bread';
import { NgxSpinnerService } from "ngx-spinner"; 
import { Dossier } from 'src/app/shared/model/dossier';
import { AuthService } from 'src/app/shared/services/auth.service';

  @Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {
  listCurrentMeta: JSON[]=[];
  currentJustify = 'start';
  currentOrientation = 'horizontal';
  existSommable : boolean =true;
 
  columns: JSON[]=[];// example .parse('[{"name":"Référence de dossier","prop":"ref_doss"}]');
  datas: any[]; //example .parse('[{"ref_doss":"MD780937","sexe":"Masculin","date_incr":"","age":"24","prenom":"Alain","nom":"TESTE"}]');
  data: JSON;
  selected: number=0;
  public displayedColumns=[];
  public dataSource: MatTableDataSource<any>;
    title: string;
    subtitle: string;
    columnTotal: JSON[];
    columnList: JSON[];
    currrentDossier: Dossier;
    filteredDataSource: MatTableDataSource<any>;
    searchLauncher: any;
    dossierUUID: string;
    isAdmin: boolean=false;
    dossier: Dossier;
  constructor(private requestService: RequestService,
    private dossierService: DossierService,
    private activatedRoute: ActivatedRoute,
    private SpinnerService: NgxSpinnerService,
    private authService: AuthService,
    private bread: BreadcrumbService) {
  }
 
 


  ngOnInit(): void {
    this.isAdmin = this.authService.user.roles.indexOf("admin") > -1;
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      
      this.dossierService.dossierUUID=params.get('id');
      this.dossierUUID = this.dossierService.dossierUUID;
      this.launch(params.get('id'));
      this.bread.getBreadcrumb(this.dossierService.dossierUUID).subscribe(res=>{
        this.bread.items.splice(0,this.bread.items.length);
         for (var i=res.length-1;i>=0;i--){
          this.bread.items.push(new Bread(res[i].id,res[i].name,res[i].code,res[i].children));
         }

      });
    });
    

   // this.listCurrentMeta=this.requestService.requestDetail;
 
  }

setDataSource(){
  this.dataSource = new MatTableDataSource(this.datas);
  this.filteredDataSource = new MatTableDataSource(this.datas);
  this.dataSource.filterPredicate = (data: any[], filter: string): boolean => {
    const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
     var val=(data as { [key: string]: any })[key][0].value;
     return currentTerm +  ((val!=undefined)?val+ '◬':'') ;
    }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return dataStr.indexOf(transformedFilter) != -1;
  }
}

  activetab: string='nav-dossier0';
  clic(i: number){
    this.activetab='nav-dossier'+i;
   }
   conc(i: string): string{
     return 'nav-dossier'+i;
   }
  remove(row: any){
    var i :number=this.listCurrentMeta.indexOf(row);
    this.listCurrentMeta.splice(i,1);
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }
  open(row:any){
  var i :number=this.listCurrentMeta.indexOf(row);
  if (i>=0) {
    this.selected=i+1;//car il y a le tableau à la position 0
  }else{
    this.listCurrentMeta.push(row);
    this.selected=this.listCurrentMeta.length;
  }
}

  onLinkClick(Event){}
  /**
   * Rafraichir le tableau des documents
   * @param dossierId 
   */
  refreshTable() {
    this.launch(this.dossierUUID);
  }
  launch(id:any){
    this.SpinnerService.show(); 
    this.listCurrentMeta=[];
     this.searchLauncher=this.requestService.searchResult(
      id,this.requestService.requestValue);
      this.searchLauncher.subscribe(res=>{
        this.title=res.title;
        this.dossier=res;
        this.subtitle=res.description;
        this.dossierService.currentDossierId=res.dossierId;
      
        this.currrentDossier=res;

        this.displayedColumns=[];
        this.displayedColumns.push('select')
        console.log("********************",res);
       for (var i=0;i< res.header.length;i++){

        if(res.header[i].visibleOnList){
          this.displayedColumns.push(res.header[i].prop);
        }

       }
       this.datas=res.data;
       if (this.displayedColumns.length>1){
        this.displayedColumns.push("action");
       }
       this.setDataSource();
       this.columns=res.header;
       this.getTotalCost();
       this.columnList = this.columns.filter(value=>value["type"].indexOf('list')>=0);
       this.SpinnerService.hide(); 
   });
  
   }

  /** Gets the total cost of all transactions. */
  getTotalCost(filterValue?:string) {
    this.columnTotal = this.columns.filter(value=>value["sommable"]);
    console.log("this.columnTotal test",this.columnTotal);
    this.columnTotal.forEach(element => {
      element["position"]=this.dataSource?.data?.filter((item)=>{
        var ret: boolean=false;
        if (filterValue==null) return true;
        this.columns.forEach(elt => {
          //console.log(item[elt["prop"]][0].value);
          if (item[elt["prop"]][0].value!=null){
            ret = ret || (item[elt["prop"]][0].value.toLowerCase().indexOf(filterValue.toLowerCase())>=0);
          }
        });
        return ret;
      })
      .reduce((acc, line) => acc + +line[element["prop"]][0].value, 0);
    });
  }

   ngOnDestroy(){

   }

}