<div class="d-flex justify-content-end">
    <div class="search-container">
        <input class="form-control-plaintext" [(ngModel)]="queryString"
            type="text" autocomplete="off" placeholder="Filtrer....">
    </div>
</div>
<div fxLayout="column" fxLayout.gt-sm="row wrap" class="p-2">
    <ng-container>
        <div fxFlex="20" class="p-4" *ngFor="let dos of (dossier | async)?.children  | propFilter: {key:queryString, keyToFilter: 'name'}">
            <mat-card class="custom-card" (click)="wClick(dos)">
                <mat-card-header class="bg-primary2 p-1" fxLayoutAlign="space-between center">
                    <mat-card-title class="m-0 pt-2">
                        <h3>{{dos.name}}</h3>

                    </mat-card-title>

                </mat-card-header>
                <mat-card-content>
                    <p>
                        {{dos?.description}}
                    </p>
                </mat-card-content>
                <mat-divider></mat-divider>
                <mat-card-actions fxLayoutAlign="center center" class="secondary-text-color">
                    <mat-chip-list>
                        <mat-chip>
                            <h4 class="mb-0 counter" [CountTo]="dos.children.length" [from]="0" [duration]="1"> </h4>
                        </mat-chip>
                    </mat-chip-list>
                </mat-card-actions>
            </mat-card>
        </div>
    </ng-container>