import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dossier } from '../../shared/model/dossier';
import { unescapeLeadingUnderscores } from 'typescript';
import { PropertyService } from '../../shared/services/property.service';
import { Property } from '../../shared/model/property';
import { DossierService } from '../../shared/services/dossier.service';
import { ToastrService } from 'ngx-toastr';
import { AdminModifDossComponent } from '../admin-modif-doss/admin-modif-doss.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../shared/services/auth.service';
import { ElkService } from 'src/app/shared/services/elk.service';
import { Result } from 'src/app/shared/data/result';
import { DataUploaderComponent } from '../../shared/data-uploader/data-uploader.component';
import { DuplicationComponent } from '../duplication/duplication.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  @Input() listDossiers: Dossier[];
  @Output() dossEmitter: EventEmitter<Dossier> = new EventEmitter<Dossier>();
  expand: boolean = false;
  fileToUpload: any;
  nouvdoss: Dossier;
  ;
  listProperty: Property[];
  dossier: Dossier;
  constructor(private propertyService: PropertyService,
    private dossierService: DossierService,
    private toastrService: ToastrService,
    private authService: AuthService,
    public uploadService: ElkService,
    public dialog: MatDialog) {
    this.unselected(this.dossierService.dossiers);
  }

  ngOnInit(): void {

  }
  onClick(doss: Dossier) {
    this.unselected(this.dossierService.dossiers);
    this.propertyService.getListeProperty(doss).subscribe(res => {
      this.listProperty = res;
      this.propertyService.listProperties.splice(0, this.propertyService.listProperties.length);
      this.listProperty.forEach(element => {
        this.propertyService.listProperties.push(element);
      });
    }
    )
    doss.class = "node-selected"
    this.dossier = doss;
    this.dossEmitter.emit(doss);
  }
  unselected(listDossiers: Dossier[]) {

    listDossiers.forEach(element => {
      element.class = "node-unselected";
      this.unselected(element.children);
    });
  }
  remove(doss: Dossier) {
    if (confirm("Voulez-vous vraiment supprimer ce dossier?")) {
      this.dossierService.removeDossier(doss).subscribe(res => {
        this.toastrService.success("Le dossier est supprimé avec succès");
        this.listDossiers.splice(this.listDossiers.indexOf(doss), 1);
      },
        error => { this.toastrService.error("Une erreur est survenue lors de la suppression du dossier") })
    }
  }
  uploadFile(doss, e) {
    let dialogRef = this.dialog.open(DataUploaderComponent, {
      data: environment.backendUrl + "dossier/" + this.dossier.dossierEncoded + "/api/excel"
    });
  }

  duplicate(doss, e) {
    let dialogRef = this.dialog.open(DuplicationComponent, {
      data: this.dossier
    });
  }

  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => resolve(btoa(reader.result.toString()));
      reader.onerror = error => reject(error);
    });
  }
  openModal(doss: Dossier) {
    this.nouvdoss = new Dossier(null, '', '', '', doss.dossierId, []);
    let dialogRef = this.dialog.open(AdminModifDossComponent, {
      data: this.nouvdoss //A revoir le dossier est undifened
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dossierService.getDossiersByParent(doss.dossierId, this.authService.user).subscribe(res => {
        doss.children.splice(0, doss.children.length);
        res.forEach(element => {
          doss.children.push(element);
        });
      })

    });
  }
}
function DuplicateComponent(DuplicateComponent: any, arg1: { data: Dossier; }) {
  throw new Error('Function not implemented.');
}

