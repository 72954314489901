import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PropertyService } from '../../shared/services/property.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Property } from 'src/app/shared/model/property';
import { ReferenceService } from 'src/app/shared/services/reference.service';
import { Reference } from 'src/app/shared/model/reference';
interface TypeValue {
  name: string;
}
@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss']
})
export class AddPropertyComponent implements OnInit {
  typesValues: TypeValue[] = [
    {
      name:'title'
    },

    {
      name:'text'
    },
    {
      name:'richtext'
    },
    {
      name:'number'
    },
    {
      name:'date'
    },
    {
      name:'time'
    },
    {
      name:'list'
    },
    {
      name:'tel'
    }
    ,
    {
      name:'email'
    }

  ]
  propertyGroup: FormGroup;
  references : Reference[];
  prop: Property;
  mode: string;
  constructor(private fb: FormBuilder, private propertyService: PropertyService,
    private referenceService: ReferenceService,
    public dialogRef: MatDialogRef<AddPropertyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

    ) {
      this.prop = data.prop;
      this.mode = data.mode;
   }

  ngOnInit(): void {
    this.initForm();
    if (this.mode == 'update') {
      console.log(this.prop);
      this.propertyGroup.patchValue({...this.prop});
    }
   this.referenceService.getListReference(0).subscribe(
     res=>this.references=res,
     err=>this.references.push(new Reference("Problème lors de la réception des lsite",0))
   );
  }

  /**
   * Initialisation du formulaire de création de properties
   */
  initForm() {
    this.propertyGroup = this.fb.group({
      propertyId: [null],
      key: ['', [Validators.required]],
      position: [0,[Validators.required, Validators.min(1)]],
      required: [false],
      listValues: [null],
      readonly: [false],
      minvalue: [0],
      maxvalue: [0],
      hasmin: [false],
      hasmax: [false],
      visibleOnList: [true],
      type: ['', [Validators.required]],
      field: [this.makeid(10) + this.makeid(6)],
      sommable:[false],
      pattern: ''
    })
  }
  get hasmin() { return this.propertyGroup.get('hasmin').value; }
  get hasmax() { return this.propertyGroup.get('hasmax').value; }

   makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
 /**
  * Add property
  */
  onAddProperty() {
    this.propertyGroup.value['readonly'] = this.propertyGroup.value['readonly'] ? 1:0;
    this.propertyGroup.value['visibleOnList'] = this.propertyGroup.value['visibleOnList'] ? 1:0;
    this.propertyGroup.value['required'] = this.propertyGroup.value['required'] ? 1:0;
    this.propertyGroup.value['hasmin'] = this.propertyGroup.value['hasmin'] ? 1:0;
    this.propertyGroup.value['hasmax'] = this.propertyGroup.value['hasmax'] ? 1:0;
    this.propertyService.saveProperty(this.propertyGroup.value)
    .subscribe(resp => {
      console.log(resp);
      this.dialogRef.close(resp);
    })
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
}
