import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Dossier } from 'src/app/shared/model/dossier';
import { ElkService } from 'src/app/shared/services/elk.service';

@Component({
  selector: 'app-data-uploader',
  templateUrl: './data-uploader.component.html',
  styleUrls: ['./data-uploader.component.scss']
})
export class DataUploaderComponent implements OnInit {
  endPoint: string;
  fileSize: number;

  constructor( private toastrService: ToastrService,
    public uploadService: ElkService,private fb: FormBuilder, 
    @Inject(MAT_DIALOG_DATA) public endpoint: string) { 
      this.endPoint=endpoint;
    }

  useMessage: string = "Choisir un fichier au format Excel (xls, xlsx) à importer.";
  public files: FileList;
  sizeError: boolean;
  errorMessage: string;
  form: FormGroup;
  progress: number = 0;
  uploading: boolean = false;
  

  ngOnInit(): void {

    this.form = this.fb.group({
      name: [''],
      avatar: [null]
    })
  }
  public selectFile(event) {
    this.files = event.srcElement.files;
    this.fileSize = this.files[0].size;
    this.errorMessage = "";
    this.sizeError = false;
  }
  public uploadFile() {

    if (this.files == null) {
      alert("Aucun fichier sélectionné.");
      return;
    }
    let file = this.files[0];
    if (file.size > 5120000) {
      this.sizeError = true;
      this.errorMessage = "Le fichier que vous avez choisi dépasse la taille maximale autorisée (5Mo).";
    } else {
      this.uploading = true;
        this.uploadService.uploadExcelFile(file,this.endPoint)
            .subscribe((event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  console.log('Request has been made!');
                  break;
                case HttpEventType.ResponseHeader:
                  console.log('Response header has been received!');
                  break;
                case HttpEventType.UploadProgress:
                  this.progress = Math.round(event.loaded / event.total * 100);
                  break;
                case HttpEventType.Response:
                  console.log('Document successfully created!', event.body);
                  setTimeout(() => {
                    this.progress = 0;
                    this.toastrService.success("Le fichier a été bien importé.");
                  }, 1500);

              }
            })
      
    }

  }

}
