import { Component, OnDestroy, OnInit } from '@angular/core';
import { DossierService } from '../../shared/services/dossier.service';
import { Dossier } from '../../shared/model/dossier';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AdminModifDossComponent } from '../admin-modif-doss/admin-modif-doss.component';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { PropertyService } from '../../shared/services/property.service';
import { Property } from '../../shared/model/property';
import { ToastrService } from 'ngx-toastr';
import { AddPropertyComponent } from '../add-property/add-property.component';
import { Validators } from '@angular/forms';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { Bread } from 'src/app/shared/model/bread';
@Component({
  selector: 'app-admin-accueil',
  templateUrl: './admin-accueil.component.html',
  styleUrls: ['./admin-accueil.component.scss']
})

export class AdminAccueilComponent implements OnInit, OnDestroy {
  dossier: Dossier;
  nouvdoss: Dossier;
  nouveau: boolean = false;
  listDossiers: Dossier[];
  allProperties: Property[] = [];
  properties: Property[] = [];
  searchText: string = "";
  public BAG = "DRAGULA_EVENTS";
  MANY_ITEMS = 'MANY_ITEMS';
  inProgess: boolean = false;
  public subs = new Subscription();
  public subsList = new Subscription();
  constructor(
    private dossierService: DossierService,
    private dragulaService: DragulaService,
    public dialog: MatDialog,
    private propertiesService: PropertyService,
    private toastrService: ToastrService,
    private br: BreadcrumbService
  ) {
    this.draggable();
  }

  ngOnInit(): void {
    this.getListDossiers();
    this.getAllProperties();
    this.br.items.splice(0, this.br.items.length);
    this.br.items.push(new Bread("0", "Paramétrage", "/#", []));
  }
  refresh() {
    this.getListDossiers();
    this.getAllProperties();
  }
  /**
   * Get Liste dossier
   */
  getListDossiers() {
    this.subsList = this.dossierService.getTemplateByParent(0).subscribe(
      listDoss => {
        this.listDossiers = listDoss;
        listDoss.forEach(element => {
          this.dossierService.dossiers.push(element);
        });
      });
  }
  /**
   * 
   * @param doss 
   */
  onClick(doss: Dossier) {
    this.properties = [];
    this.dossier = doss;
    console.log("dossier 1", this.dossier);
    this.findPropertiesNotInDossier(doss);
    console.log("properties 2", this.properties);
  }

  /**
   * 
   * @param dossier 
   */
  findPropertiesNotInDossier(dossier: Dossier) {
    this.allProperties.forEach(p => {
      if (dossier.properties != null) {
        console.log("some 3s", dossier.properties.some(pro => pro.field != p.field))
        if (!dossier.properties.some(pro => pro.field == p.field)) {
          this.properties.push(p);
        }
      }
    })
  }
  /**
   * Get All properties
   */
  getAllProperties() {
    this.propertiesService.getAll()
      .subscribe(properties => {
        console.log(properties);
        this.allProperties = properties;
      })
  }
  /**
   * Open dialog to add dossier
   */
  openModal() {
    this.nouvdoss = new Dossier(null, '', '', '', 0, []);
    let dialogRef = this.dialog.open(AdminModifDossComponent, {
      width: '600px',
      data: this.nouvdoss //A revoir le dossier est undifened
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.getListDossiers();
      }
    });
  }
  /**
   * Enregistrer le dossier
   */
  enregistrerDossier() {
    this.inProgess = true;
    console.log(this.dossier.properties);
    console.log("aller", this.dossier.properties);
    this.dossierService.updateDossier(this.dossier)
      .subscribe(resp => {
        this.dossier = resp;
        this.inProgess = false;
        console.log("retour", this.dossier.properties);
        this.toastrService.success(`Le dossier ${this.dossier.name} est enregistré avec succès`);
      }, err => {
        console.log(err);
        this.toastrService.warning(`Erreur lors de l'enregistrement du dossier ${this.dossier.name}`);
        this.inProgess = false;
      })
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /**
   * Ajout de properties
   */
  addPropriete() {

    let dialogRef = this.dialog.open(AddPropertyComponent, {
      width: '600px',
      data: {
        prop: undefined,
        mode: 'create'
      } //A revoir le dossier est undifened
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.properties.push(result);
        // this.findPropertiesNotInDossier(this.dossier);
      }
    });
  }
  /**
   * Modify dossier
   */
  modifier() {
    console.log(this.dossier);
    let dialogRef = this.dialog.open(AdminModifDossComponent, {
      width: '600px',
      data: this.dossier //A revoir le dossier est undifened
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  /**
   * Initialize dragula
   */
  draggable() {
    this.subs.add(this.dragulaService.drag(this.BAG)
      .subscribe(({ el }) => {
        this.removeClass(el, 'ex-moved');
      })
    );
    this.subs.add(this.dragulaService.drop(this.BAG)
      .subscribe(({ el }) => {
        this.addClass(el, 'ex-moved');
      })
    );
    this.subs.add(this.dragulaService.over(this.BAG)
      .subscribe(({ el, container }) => {
        this.addClass(container, 'ex-over');
      })
    );
    this.subs.add(this.dragulaService.out(this.BAG)
      .subscribe(({ el, container }) => {
        this.removeClass(container, 'ex-over');
      })
    );
  }
  /**
   * Remove class by name
   * @param el 
   * @param name 
   */
  private removeClass(el: Element, name: string): void {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)', 'g'), '');
    }
  }
  /**
   * Has class
   * @param el 
   * @param name 
   */
  private hasClass(el: Element, name: string): any {
    return new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)').test(el.className);
  }

  /**
   * Add class
   * @param el 
   * @param name 
   */
  private addClass(el: Element, name: string): void {
    if (!this.hasClass(el, name)) {
      el.className = el.className ? [el.className, name].join(' ') : name;
    }
  }
  /**
   * Open dialog for create dossier
   */
  open() {
    let dialogRef = this.dialog.open(AdminModifDossComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    })
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.subsList.unsubscribe();
  }
  compare(a, b) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }
}
