import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Dossier } from 'src/app/shared/model/dossier';
import { DossierService } from 'src/app/shared/services/dossier.service';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent implements OnInit {
  dossier: Observable<Dossier>;
  mySubscription: any;
  queryString: string = "";
  constructor(private activatedRoute: ActivatedRoute,
    private dossierService: DossierService,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
    let dossierId = activatedRoute.snapshot.paramMap.get('dossierId');
    this.dossier = dossierService.getDossierByUuid(dossierId);
  }

  ngOnInit(): void {


  }
  wClick(dossier: Dossier) {
    this.dossierService.setWidgetData([dossier]);
  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

}
