<div class="container-fluid">
      <form [formGroup]="objectForm" (ngSubmit)="save()" id="childFormData" class="row">
      <div class="col-4">
       
          <!--buttons-->
          <div>
            <label class="btn-action" *ngIf="!edit && !dossier?.close">
              <button class="btn btn-secondary" (click)="modifier()"> Modifier</button>
            </label>
            <label class="btn-action" *ngIf="edit">
              <button class="btn btn-secondary" type="button" (click)="save()" [disabled]="!objectForm.valid" >
                Enregistrer</button>
            </label>
            &nbsp;
            <label class="btn-action" *ngIf="edit">
              <button class="btn btn-secondary" type="button" (click)="sendFile()"> Nouveau message</button>
            </label>
          </div>
          <div>
            <mat-accordion>
              <mat-expansion-panel [expanded]="edit">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Données
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class='form-group' *ngFor="let col of columns">

                  <label for='name'*ngIf="col.type!='richtext'"><b>{{col.name}}</b>&nbsp;&nbsp;</label>
  
                  <span *ngIf="(!edit || col.readonly ) && col.type!='date'"
                    class="form-control">{{curMeta[col.prop][0]["value"]}}</span>
                  <span *ngIf="(!edit|| col.readonly)  && col.type=='date'"
                    class="form-control">{{(curMeta[col.prop][0]["value"])?(curMeta[col.prop][0]["value"] |
                    date:"dd/MM/yyyy"):''}} </span>
  
  
                  <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
                    *ngIf="!col.readonly && edit && col.type=='text'">
                    
                  <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="number" class='form-control'
                    *ngIf="!col.readonly && edit && col.type=='number'">
                  <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="date" class='form-control'
                    *ngIf="!col.readonly && edit && col.type=='date'">
                  <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="time" class='form-control'
                    *ngIf="!col.readonly && edit && col.type=='time'">
                  <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="tel" class='form-control'
                    *ngIf="!col.readonly && edit && col.type=='tel'">
                  <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
                    *ngIf="!col.readonly && edit && col.type=='email'">
  
  
                  <select *ngIf="edit && col.type=='list' && col.list.display=='liste'"
                    [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
                    <option *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}</option>
                  </select>
                  <mat-radio-group *ngIf="edit && col.type=='list' && col.list.display=='radio'"
                    [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
                    <mat-radio-button *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}
                    </mat-radio-button>&nbsp;&nbsp;
                  </mat-radio-group>
                </div>
                <!--<app-data-viewer [columns]="columns" (objForm)="save($event)"></app-data-viewer>-->
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!--end buttons-->
  
  
  
  
      </div>
      <div class="col-8" style="overflow: auto" >

        <div class='form-group' *ngFor="let col of columns">
          <ng-container *ngIf="col.type=='richtext'">
          <label for='name'><b>{{col.name}}</b>&nbsp;&nbsp;</label>
          <ck-editor [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" name="editor1" skin="moono-lisa" language="fr" [fullPage]="true"></ck-editor>
  </ng-container>
      </div>
      <!--
  <div class="col-4"><app-timeline></app-timeline></div>-->
    </div>
    
  </form>
