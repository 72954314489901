import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'user',
    loadChildren: () => import('../../user-space/user-space.routing').then(m => m.UserSpaceRoutingModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('../../admin/admin.routing').then(m => m.AdminRoutingModule)
  },
  {
    path: 'toto',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  }

];