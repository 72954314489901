export class Reference {
    referenceId: number;
    name: string;
    parentId: number;
    level: number;
    displayMode: string;
    expand: boolean = false;
    children: Reference[];
    workflow: boolean;
    class: string = "node-unselected";
    constructor(name: string, level: number, id?: number, workflow?: boolean) {
        this.name = name;
        this.children = [];
        this.level = level;
        this.parentId = (id != null) ? id : 0;
        this.workflow = workflow;

    }
}