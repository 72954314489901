import { Component, Input, OnInit,OnChanges } from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @Input() barChartLabels;
  @Input() barChartData;
  public barChartType = 'bar';
  public barChartLegend = true;
  
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true
          }
      }]
  }
  };


  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(){
    console.log("ngOnchanges",this.barChartData);
  }

}
