<h1 mat-dialog-title>DUPLIQUER UN DOSSIER</h1>
<div mat-dialog-content>

  <div class="form-group">
    <label for="exampleFormControlInput1">Libellé</label>
    <input type="text" class="form-control" [(ngModel)]="dossier.name">
  </div>
  <div class="form-group">
    <label for="exampleFormControlInput1" id="description">Description</label>
    <input type="text" class="form-control" id="description" [(ngModel)]="dossier.description">
  </div>
  <div class="form-group">
    <label for="exampleFormControlInput1" id="mailaddress">Adresses mail </label>
    <input type="text" class="form-control" id="mailaddress" [(ngModel)]="dossier.canSendMail">
  </div>
  <div class="form-group">
    <label for="exampleFormControlInput1" id="telephone">Téléphone </label>
    <input type="text" class="form-control" id="telephone" [(ngModel)]="dossier.smsReceiver">
  </div>
  <div class="form-group">
    <label for="exampleFormControlInput1" id="code">Code</label>
    <input type="text" class="form-control" readonly [(ngModel)]="dossier.code">
  </div>
  <div class="form-group">
    <mat-slide-toggle [(ngModel)]="dossier.codedifAuto">Activer la codification automatique</mat-slide-toggle>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="close()" tabindex="-1">Fermer</button>
  <button mat-raised-button color="primary" (click)="save()" tabindex="2">Enregistrer</button>
</div>