<div class="container-fluid">
  <div class="row">
    <div class="col-12" style="padding: 0;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <button mat-icon-button class="example-icon btn-refresh" matTooltip="Rafraîchir le tableau"
              (click)="refresh()" aria-label="Example icon-button with share icon">
              <mat-icon>cached</mat-icon>
            </button>
          </mat-card-title>
          <mat-grid-list cols="10" rowHeight="50px" class="mat-card-header-text w-100">
            <mat-grid-tile [colspan]="7" class="mat-grid-titre">
              <mat-form-field>
                <input matInput (keyup)="applyFilter($event)" [value]="" #input placeholder="Filter">
              </mat-form-field>
            </mat-grid-tile>
            <!--buttons-->
            <mat-grid-tile [colspan]="3">

              <div class="btn-group d-flex justify-content-end" role="group"
                aria-label="Button group with nested dropdown" style="margin-right: 56px;">

                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="uploadFile()"><i
                    class="fa-solid fa-scanner"></i>Importer</button>
                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="newUser()"><i
                    class="fa-solid fa-scanner"></i> Nouveau
                  client</button>

              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-header>
        <mat-card-content>
          <!--/buttons-->
          <div class="mat-elevation-z8 ">
            <mat-table [dataSource]="dataSource" class="w-100">
              <!-- Columns -->
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Id</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.id}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Pr&eacute;nom</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.firstName}}
                </mat-cell>
                <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
              </ng-container>

              <ng-container matColumnDef="lastName">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Nom</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.lastName}}
                </mat-cell>
                <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
              </ng-container>

              <ng-container matColumnDef="username">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Nom d'utilisateur</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.username}}
                </mat-cell>
                <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
              </ng-container>

              <ng-container matColumnDef="enterprise">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Dossier client</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.enterprise}}
                </mat-cell>
                <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Courriel</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.email}}
                </mat-cell>
                <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
              </ng-container>

              <ng-container matColumnDef="enabled">
                <mat-header-cell *matHeaderCellDef>
                  <h2>Statut</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{row.enabled?'actif':'inactif'}}
                </mat-cell>
                <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
              </ng-container>

              <!-- Action Column -->
              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="header-align-right">
                  <h2>Action</h2>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="action-link" class="header-align-right"
                  style="padding-right: 12px;">
                  <table style="width:100px">
                    <tr>
                      <td> <a style="cursor: pointer;" (click)="open(row)" title="Ouvrir"><i class="icon-eye"></i></a>
                      </td>
                    </tr>
                  </table>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              <!-- Row shown when there is no matching data. -->
              <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr> -->
              <tr class="mat-row" *matNoDataRow>
                <!-- <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td> -->
                <td class="mat-cell" colspan="4">Aucun résultat pour le filter "{{input.value}}"</td>
              </tr>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" [length]="dataSource.data.length" [pageSize]="5"
              showFirstLastButtons></mat-paginator>
            <!-- <mat-paginator [length]="datas.length" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"></mat-paginator> -->
          </div>
        </mat-card-content>
        <mat-card-footer>

        </mat-card-footer>
      </mat-card>

    </div>
  </div>
</div>