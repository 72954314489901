<ul class="list-group treeview">
  <li *ngFor="let doss of listDossiers" class=" node-treeview-searchable " data-nodeid="0">
    <div [class]="doss.class">
      <span class="indent" *ngIf="doss.parentId!=0"></span>
      <span class="fa fa-folder-o" *ngIf="doss.children.length>0 && !doss.expand" (click)="doss.expand=!doss.expand">

      </span>
      <span class="fa fa-folder-open-o" *ngIf="doss.children.length>0 && doss.expand "
        (click)="doss.expand=!doss.expand">
      </span>
      <span class="icon node-icon" *ngIf="doss.children.length==0"></span>
      <span (click)="onClick(doss)">&nbsp;{{doss.name}}</span>
      <button type="button" class="close d-flex justify-content-end" (click)="remove(doss)" aria-label="Close"
        *ngIf="doss.documents.length==0 && doss.children.length==0 && doss.properties.length==0 ">
        <i class="fa fa-trash-o"></i>
      </button>
      <button type="button" class="close d-flex justify-content-end" (click)="openModal(doss)"
        title="Nouveau sous dossier">
        <i class="fa fa-plus"></i>
      </button>
      <button type="button" class="close d-flex justify-content-end" (click)="uploadFile(doss,$event)"
        title="Importer un fichier Excel" *ngIf="doss.parentId>0 && doss.properties.length>0">
        <i class="fa fa-upload" aria-hidden="true"></i>
      </button>
      <button type="button" class="close d-flex justify-content-end" (click)="duplicate(doss,$event)"
        title="Dupliquer le dossier" *ngIf="doss.clone">
        <i class="fa fa-copy" aria-hidden="true"></i>
      </button>
    </div>
    <div class="pl-10">
      <app-tree [listDossiers]="doss.children" *ngIf="doss.expand" (dossEmitter)="onClick($event)"></app-tree>
    </div>
  </li>
</ul>
<!-- <input type="file" id="file-input"   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (change)="handleFileSelect($event)" />
      <ul class="list-group"> 
        <li class="list-group-item node-treeview-searchable search-result" data-nodeid="1" style="color:#D9534F;background-color:undefined;">
          <span class="indent"></span><span class="icon expand-icon glyphicon glyphicon-minus"></span>
          <span class="icon node-icon"></span>Child 1</li>
          <li class="list-group-item node-treeview-searchable" data-nodeid="2" style="color:undefined;background-color:undefined;">
            <span class="indent"></span>
            <span class="indent"></span>
            <span class="icon glyphicon"></span>
            <span class="icon node-icon"></span>Grandchild 1
          </li>
          <li class="list-group-item node-treeview-searchable" data-nodeid="3" style="color:undefined;background-color:undefined;">
            <span class="indent"></span>
            <span class="indent"></span>
            <span class="icon glyphicon"></span>
            <span class="icon node-icon"></span>Grandchild 2
          </li>
          <li class="list-group-item node-treeview-searchable" data-nodeid="4" style="color:undefined;background-color:undefined;">
            <span class="indent"></span>
            <span class="icon glyphicon"></span>
            <span class="icon node-icon"></span>Child 2
          </li>
          <li class="list-group-item node-treeview-searchable" data-nodeid="5" style="color:undefined;background-color:undefined;">
              <span class="icon expand-icon glyphicon glyphicon-plus">
                </span>
            <span class="icon node-icon"></span>Parent 2
          </li>
          
          <li class="list-group-item node-treeview-searchable" data-nodeid="6" style="color:undefined;background-color:undefined;"><span class="icon glyphicon"></span><span class="icon node-icon"></span>Parent 3</li><li class="list-group-item node-treeview-searchable" data-nodeid="7" style="color:undefined;background-color:undefined;"><span class="icon glyphicon"></span><span class="icon node-icon"></span>Parent 4</li><li class="list-group-item node-treeview-searchable" data-nodeid="8" style="color:undefined;background-color:undefined;"><span class="icon glyphicon"></span><span class="icon node-icon"></span>Parent 5</li>
    -->