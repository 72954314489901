<form [formGroup]="objectForm" (ngSubmit)="save()" id="childFormData">
    <div class='form-group' *ngFor="let col of columns">
        <label for='name'><b>{{col.name}}</b>&nbsp;&nbsp;</label>

        <span *ngIf="(!edit || col.readonly ) && col.type!='date'"
            class="form-control">{{curMeta[col.prop][0]["value"]}}</span>
        <span *ngIf="(!edit|| col.readonly)  && col.type=='date'"
            class="form-control">{{(curMeta[col.prop][0]["value"])?(curMeta[col.prop][0]["value"] |
            date:"dd/MM/yyyy"):''}} </span>


        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
            *ngIf="!col.readonly && edit && col.type=='text'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="number" class='form-control'
            *ngIf="!col.readonly && edit && col.type=='number'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="date" class='form-control'
            *ngIf="!col.readonly && edit && col.type=='date'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="time" class='form-control'
            *ngIf="!col.readonly && edit && col.type=='time'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="tel" class='form-control'
            *ngIf="!col.readonly && edit && col.type=='tel'">
        <input [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']" type="text" class='form-control'
            *ngIf="!col.readonly && edit && col.type=='email'">


        <select *ngIf="edit && col.type=='list' && col.list.display=='liste'"
            [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
            <option *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}</option>
        </select>
        <mat-radio-group *ngIf="edit && col.type=='list' && col.list.display=='radio'"
            [formControlName]="col.prop+'_'+curMeta[col.prop][0]['id']">
            <mat-radio-button *ngFor="let val of col.list.listValues" [value]="val.value">{{val.value}}
            </mat-radio-button>&nbsp;&nbsp;
        </mat-radio-group>
    </div>
</form>