import { Component, OnInit, Inject } from '@angular/core';
import { MailMessage } from '../../shared/model/mail.message';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailService } from '../../shared/services/email.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-send-file',
  templateUrl: './send-file.component.html',
  styleUrls: ['./send-file.component.scss']
})
export class SendFileComponent implements OnInit {
  newMail: MailMessage;
  sending : boolean=false;
  mailGroup: FormGroup;
  constructor(   public dialogRef: MatDialogRef<SendFileComponent>,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private emailService: EmailService,
@Inject(MAT_DIALOG_DATA) public data: MailMessage) {
  this.newMail=data;
 }

ngOnInit(): void {
  this.mailGroup = this.fb.group({
    to: [this.newMail.to, [Validators.required, Validators.pattern("^([a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}(,|;| ){0,1})+$")]],
    subject: [this.newMail.subject, [Validators.required]],
    message: [this.newMail.message],
  })
}
send(){
  this.sending=true;
  this.newMail.to = this.mailGroup.controls.to.value;
  this.newMail.subject = this.mailGroup.controls.subject.value;
  this.newMail.message = this.mailGroup.controls.message.value;
    this.emailService.sendMail(this.newMail).subscribe(res=>{
        this.toastrService.success("Le message a été envoyé avec succès."),
        this.sending=false;
        this.dialogRef.close();
    }, err => {
      if (err.status == 200) {
        this.toastrService.success("Le message a été envoyé  avec succès."),
        this.sending=false;
        this.dialogRef.close();
      }
    });
}
close(): void {
    this.dialogRef.close();
}

}
