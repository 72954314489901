import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Dossier } from 'src/app/shared/model/dossier';
import { DossierService } from 'src/app/shared/services/dossier.service';

@Component({
  selector: 'app-duplication',
  templateUrl: './duplication.component.html',
  styleUrls: ['./duplication.component.scss']
})
export class DuplicationComponent implements OnInit {
  dossier: Dossier; oriDossier: Dossier;

  constructor(private dossierService: DossierService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<DuplicationComponent>,
    //public activeModal: NgbActiveModal

    @Inject(MAT_DIALOG_DATA) public data: Dossier
  ) {
    this.oriDossier = data;

  }

  public files: FileList;
  sizeError: boolean;
  errorMessage: string;
  form: FormGroup;
  creating: boolean = false;


  ngOnInit(): void {
    this.dossier = { ...this.oriDossier };
    this.dossier.dossierId = null;
  }

  close(): void {
    this.dialogRef.close(false);
  }
  save(): void {

    if (this.dossier.dossierId == null) {
      this.creating = true;
      this.dossierService.duplicate(this.dossier, this.oriDossier.dossierEncoded).subscribe(dos => {
        this.dossier = dos;
        this.creating = false;
        this.toastrService.success("Dossier sauvegardé avec succès.")
        this.dialogRef.close(true);
      },
        error => {
          this.toastrService.error("Une erreur s'est produite lors de l'enregistrement du dossier")
          this.dialogRef.close(false);
        });
    }

  }

}
