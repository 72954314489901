<div class="card">
    <div class="card-header">
        <h2>Ajout de propriétés</h2>
    </div>
    <div class="card-body">
        <form [formGroup]="propertyGroup" class="container-fluid" (submit)="onAddProperty()">
            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <mat-label>Libellé</mat-label>
                    <input matInput formControlName="key">
                </mat-form-field>
            </div>
            
            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <mat-label>Position</mat-label>
                    <input matInput formControlName="position">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-slide-toggle formControlName="required">Obligatoire</mat-slide-toggle>
            </div>
           
            <div class="form-group">
                <mat-slide-toggle formControlName="readonly">Lecture seule</mat-slide-toggle>
            </div>
            <div class="form-group">
                <mat-slide-toggle formControlName="visibleOnList">Rendre visible</mat-slide-toggle>
            </div>
            <div class="form-group">
                <mat-form-field appearance="fill">
                    <mat-label>Types</mat-label>
                    <mat-select formControlName="type" #type>
                      <mat-option>--</mat-option>
                      <mat-option *ngFor="let t of typesValues" [value]="t.name">
                        {{t.name}}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="propertyGroup['type'].hasError('required')">Selectionner un type</mat-error> -->
                    <!-- <mat-hint>{{animalControl.value?.sound}}</mat-hint> -->
                  </mat-form-field>
            </div>
            <div class="form-group" *ngIf="type.value == 'number'">
                <mat-slide-toggle formControlName="sommable">Sommable</mat-slide-toggle>
            </div>
            <div class="form-group" *ngIf="type.value == 'number'">
                <mat-slide-toggle formControlName="hasmin">Valeur minimale</mat-slide-toggle>
                <mat-form-field class="example-full-width" *ngIf="hasmin">
                <input matInput formControlName="minvalue" >
                </mat-form-field>
            </div>
            <div class="form-group" *ngIf="type.value == 'number'">
                <mat-slide-toggle formControlName="hasmax">Valeur maximale</mat-slide-toggle>
                <mat-form-field class="example-full-width" *ngIf="hasmax">
                <input matInput formControlName="maxvalue" >
                </mat-form-field>
            </div>
            <div class="form-group" *ngIf="type.value == 'list'">
                <mat-form-field class="example-full-width">
                    <mat-label>Liste de valeurs</mat-label>
                    <mat-select formControlName="listValues" #listValues>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let ref of references" [value]="ref.referenceId">
                          {{ref.name}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field appearance="fill">
                    <mat-label>Modèle de recherche</mat-label>
                    <input matInput formControlName="pattern">
                    <!-- <mat-error *ngIf="propertyGroup['type'].hasError('required')">Selectionner un type</mat-error> -->
                    <!-- <mat-hint>{{animalControl.value?.sound}}</mat-hint> -->
                  </mat-form-field>
            </div>
            <div class="card-footer">
                <button class="mr-5" [disabled]="!propertyGroup.valid" mat-stroked-button type="submit" color="primary">Enregistrer</button>
                <button mat-stroked-button color="accent" (click)="closeDialog()">Annuler</button>
            </div>
        </form>
    </div>
</div>

