import { Component, OnInit } from '@angular/core';
import {DossierService} from '../../shared/services/dossier.service';
import { Dossier } from '../../shared/model/dossier';
import { MetaData } from '../../shared/model/metadata';
import { RequestService } from '../../shared/services/request.service';
@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.scss']
})
export class LeftSideBarComponent implements OnInit {
  listDossiersLev1: Dossier[];
  listDossiersLev2: Dossier[];
  listDossiersLev3: Dossier[];
  listDossiersLev4: Dossier[];
  listMetadataLev1: MetaData[];
  listMetadataLev2: MetaData[];
  listMetadataLev3: MetaData[];
  listMetadataLev4: MetaData[];
  listMetadata: MetaData[];
  ope: string = "=";
  operators: string[];
  valeur: number;valeur1: number;valeur2: number;
  valeur3: number;
  metaData: string[]=["ABCFD","ABEDF","ACV"];
  vMetaData: string;metaIndex: number;
  constructor(private dossierService: DossierService,
    private requestService: RequestService) {
      // this.listDossiersLev1 = dossierService.getDossiersByParent(1);
      // this.listMetadataLev1 = this.dossierService.getMetadataByParent(1);
       //this.listMetadata = this.dossierService.getMetadataByParent(0);
       //this.listMetadata = this.listMetadataLev1;
      this.operators=["=","<>","LIKE"];
      }
  isopen: boolean;
  ngOnInit() {
    this.isopen = false;
  }
   openNav() {
    document.getElementById("mySidepanel").style.width = (this.isopen)?"15px":"250px";
    this.isopen=!this.isopen;
   }
  setDossiersLev2(id: number){
    this.listDossiersLev2 =[];// this.dossierService.getDossiersByParent(id);
    this.listMetadataLev2 = [];//this.dossierService.getMetadataByParent(id);
    this.listMetadata = this.listMetadataLev2;
  }
  setDossiersLev3(id: number){
    this.listDossiersLev3 =[];// this.dossierService.getDossiersByParent(id);
    this.listMetadataLev3 = [];//this.dossierService.getMetadataByParent(id);
    this.listMetadata = this.listMetadataLev3;
   
  }
  setDossiersLev4(id: number){
    this.listDossiersLev4 = [];//this.dossierService.getDossiersByParent(id);
    this.listMetadataLev4 = [];//this.dossierService.getMetadataByParent(id);
    this.listMetadata = this.listMetadataLev4;
  }
  setOpe(id: number){
    this.ope=this.operators[id-1];
  }
  setRequest(){
    this.requestService.addRequestString(this.listMetadata[this.metaIndex].key," OR ",this.vMetaData,this.ope,this.listMetadata[this.metaIndex].field);
    console.log(this.requestService.requestValue);
  }
}
