import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ElkService } from 'src/app/shared/services/elk.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-visualize',
  templateUrl: './visualize.component.html',
  styleUrls: ['./visualize.component.scss']
})
export class VisualizeComponent implements OnInit {
  allDocuments: JSON[];
  fileUrl: string;
  viewer: string = "pdf";;
  doc: JSON;
  public fileBlob;
  dossierId: string;
  index: number;
  totalImages: number;
  currentImageIndex: number;

  constructor(private elkService: ElkService,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public data: JSON[]) {
    this.allDocuments = data['data'];
    this.dossierId = data['dossierId']
  }

  ngOnInit(): void {
    this.index = 0;
    this.totalImages = this.allDocuments.length;
    this.currentImageIndex = this.index + 1;
    this.getFile(this.dossierId, this.allDocuments[this.index]['documentuniqueid']);
  }

  getFile(index: string, docId: string) {
    this.elkService.getFile('dossier' + index, docId).subscribe(res => {
      if (res == null) {
        this.fileUrl = "";
      } else {
        this.doc = res;
        this.fileBlob = this.fileUploadService.dataURItoBlob(this.doc["_source"]["data"], this.doc["_source"]["attachment"]["content_type"]);
        this.fileUrl = window.URL.createObjectURL(this.fileBlob);
        if (this.doc["_source"]["attachment"]["content_type"] == "application/pdf")
          this.viewer = "pdf";
      }
    },
      error => {
        this.viewer = "error";
      })
  }

  previous() {
    if (this.index > 0) {
      this.index--;
      this.currentImageIndex = this.index + 1;
      this.getFile(this.dossierId, this.allDocuments[this.index]['documentuniqueid']);
    }
  }

  next() {
    if (this.index < this.allDocuments.length - 1) {
      this.index++;
      this.currentImageIndex = this.index + 1;
      this.getFile(this.dossierId, this.allDocuments[this.index]['documentuniqueid']);
    }
  }

}
