
<h1 mat-dialog-title>AJOUTER UN DOSSIER</h1>
<div mat-dialog-content>

    <div class="form-group">
      <label for="exampleFormControlInput1">Libellé</label>
      <input type="text" class="form-control" [(ngModel)]="dossier.name">
    </div>
    <div class="form-group">
        <label for="exampleFormControlInput1" id="description" >Description</label>
        <!-- <input type="text" class="form-control" id="description" [(ngModel)]="dossier.description"> -->
        <textarea class="form-control"   id="description" [(ngModel)]="dossier.description" rows="3"></textarea>
    </div>
    <!-- <div class="form-group">
      <label for="exampleFormControlSelect1">Dossier parent</label>
      <select class="form-control"  id="parentid" [(ngModel)]="dossier.dossierId">
        <option *ngFor="let dos of listDossiers; let i=index" [value]="dos.dossierId">{{dos.name}}</option>
      </select>
    </div>       -->

</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="close()" tabindex="-1">Fermer</button>
  <button mat-raised-button color="primary" (click)="save()" tabindex="2">Enregistrer</button>
</div>

<!--
  
<h1 mat-dialog-title>AJOUTER UN DOSSIER</h1>
<div mat-dialog-content>
  <form [formGroup]="dossierForm">
    <div class="form-group">
      <label for="exampleFormControlInput1">Libellé</label>
      <input type="text" class="form-control" [formControlName]="name" >
    </div>
    <div class="form-group">
        <label for="exampleFormControlInput1">Description</label>
        <input type="text" class="form-control" [formControlName]="description" id="description" >
      </div>
    <div class="form-group">
      <label for="exampleFormControlSelect1">Dossier parent</label>
      <select class="form-control" [formControlName]="parentId" id="exampleFormControlSelect1" >
        <option *ngFor="let dos of listDossiers; let i=index" [value]="dos.dossierId"></option>
      </select>
    </div>      
</form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="save()" tabindex="2">Enregistrer</button>
  <button mat-raised-button color="warn" (click)="close()" tabindex="-1">Fermer</button>
</div>

-->