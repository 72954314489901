import { Injectable } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import {Operation} from '../model/operation'
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class OperationService extends AuthService{

  constructor(public http: HttpClient,
    public oauthService: OAuthService) {
    super(oauthService, http);
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + oauthService.getAccessToken()
      })
    }
  }

  saveAllOperation(operationList: Operation[]){
    return this.http.post<Operation[]>(`${environment.backendUrl}operation/save`,operationList,this.httpOptions);
  }

  getOperations(docId: string): Observable<Operation[]>{
    return this.http.get<Operation[]>(`${environment.backendUrl}operation/get/`+docId,this.httpOptions);
  }


  deleteOperation(journalDocId): Observable<Boolean>{
    return this.http.get<Boolean>(`${environment.backendUrl}operation/delete/`+journalDocId,this.httpOptions);
  }
}
