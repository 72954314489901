import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { RequestService } from '../../shared/services/request.service';
import {NavService} from '../../shared/services/nav.service';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-search-all-fields',
  templateUrl: './search-all-fields.component.html',
  styleUrls: ['./search-all-fields.component.scss']
})
export class SearchAllFieldsComponent implements OnInit {
  resultat: any;
  query: string;
  constructor(  private activatedRoute: ActivatedRoute,
    private requestService :RequestService,
    private auth: AuthService,
    private navService: NavService ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.query = params.get('query');
      this.requestService.searchResultAll(params.get('query'),this.auth.user).subscribe(result=>{
        console.log(result);
        this.resultat=result.content;
      })
    });
  }
}
