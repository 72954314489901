import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { UppyConfig } from 'uppy-angular';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  dossierId: string;
  constructor(public oauthService: OAuthService, @Inject(MAT_DIALOG_DATA) public data: JSON[],
  private dialogRef: MatDialogRef<UploadComponent>,
  ) {
    this.dossierId = data['dossierId'],
    this.settings.uploadAPI.endpoint = environment.backendUrl + "dossier/" + this.dossierId + "/api/upload/files";

  }

  settings: UppyConfig = {
    uploadAPI: {
      endpoint: null,
      headers: {
        Authorization: 'Bearer ' + this.oauthService.getAccessToken()
      }
    },
    plugins: {
      Webcam: false,
      GoogleDrive: false,
      Instagram: false,
      Facebook: false,
      Dropbox: false,
      ScreenCapture: false
    },
    autoProceed: true, 
    allowMultipleUploads: false,
    restrictions: {
      maxFileSize: 10240000,
      maxNumberOfFiles: 10,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image /*', '.jpg', '.jpeg', '.png', '.pdf']
    },
    showProgressDetails: true,
    meta: {
      locale: {
        strings: {
          browse: 'Importer'
        }
      }
    }
  }


  ngOnInit(): void {
 
  }

  onFileUpload(event: any) {
    console.log("event file onFileUpload == ", event)

  }
  onUploadResult(event: any) {
    console.log("event file onUploadResult == ", event);
    this.dialogRef.close(true);
  }
  upload(event: any) {
    console.log("event file == ", event)

  }

  closeModale() {

  }
}
