<div class="container-fluid">
  <div class="row">
    <div class="col-12" style="padding: 0;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <button mat-icon-button class="example-icon btn-refresh" matTooltip="Rafraîchir le tableau"
              (click)="refresh()" aria-label="Example icon-button with share icon">
              <mat-icon>cached</mat-icon>
            </button>
          </mat-card-title>
          <mat-grid-list cols="10" rowHeight="50px" class="mat-card-header-text w-100">
            <mat-grid-tile [colspan]="7" class="mat-grid-titre">
              <mat-form-field>
                <input matInput (keyup)="applyFilter($event)" [value]="" #input placeholder="Filter">
              </mat-form-field>
            </mat-grid-tile>
            <!--buttons-->
            <mat-grid-tile [colspan]="3">
              <div class="btn-group d-flex justify-content-end" role="group"
                aria-label="Button group with nested dropdown" style="margin-right: 56px;">
                <!--<div ngbDropdown class="btn-group" role="group">
                                                            <button type="button" class="btn btn-light dropdown-toggle" id="dropdownBasic1"
                                                                ngbDropdownToggle>Export</button>
                                                              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                                <button class="dropdown-item">Pdf</button>
                                                                <button class="dropdown-item">Excel</button>
                                                              </div>
                                                            </div>-->
                <!--<button class="btn btn-primary"><i class="fa fa-bold"></i></button>
                                                        -->


                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="uploadFiles()"
                  *ngIf="!dossier?.dataOnly && !dossier?.close"><i class="fa-solid fa-scanner"></i>Importer</button>
                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="reclasserDocuments()"
                  *ngIf="isAdmin && !dossier?.dataOnly && !dossier?.close" [disabled]="selection.selected.length==0"><i
                    class="fa-solid fa-scanner"></i> Reclasser</button>

                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="visualizeDocument()"
                  *ngIf="!dossier?.dataOnly && !dossier?.close" [disabled]="selection.selected.length==0"><i
                    class="fa-solid fa-scanner"></i> Visualiser</button>

                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="mergeDocument()"
                  *ngIf="!dossier?.dataOnly && !dossier?.close"><i class="fa-solid fa-scanner"></i> Fusionner</button>

                <button *ngIf="isAdmin && !dossier?.dataOnly " style="margin-left: .5em;" class="btn btn-secondary"
                  (click)="recodify()" [disabled]="dataSource.data.length==0">
                  <i class="fa"
                    [ngClass]="{'fa-spin fa-spinner':isLoading, 'fa-check':!isLoading}"></i>Recodifier</button>

                <button *ngIf="dossier?.dataOnly && !dossier?.close" style="margin-left: .5em;"
                  class="btn btn-secondary" (click)="newdoc()"><i class="fa-solid fa-arrow-up-right-from-square"></i>
                  Nouveau document</button>

                <button style="margin-left: .5em;" class="btn btn-secondary" (click)="exportTOExcel()"
                  [disabled]="dataSource.data.length==0"><i class="fa-solid fa-scanner"></i> Exporter</button>

              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-card-header>
        <mat-card-content>
          <!--/buttons-->


          <div class="example-container mat-elevation-z8 " #TABLE>
            <table mat-table #table [dataSource]="dataSource">

              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

              <!-- Position Column -->
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="matCheckboxChange($event, row)"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container *ngFor="let col of columns" [matColumnDef]="col.prop">
                <th mat-header-cell *matHeaderCellDef> {{col.name}} </th>
                <td mat-cell *matCellDef="let row">
                  {{(col.type=='number')?(row[col.prop][0].value| number:'1.2-2':'fr') :
                  ((col.type=='date')?(row[col.prop][0].value | date:'dd/MM/yyyy'): row[col.prop][0].value )}}
                </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let row">

                  <table style="width:100px">
                    <tr>
                      <td> <a style="cursor: pointer;" (click)="open(row)" title="Ouvrir"><i class="icon-eye"></i></a>
                      </td>
                      <td *ngIf="isAdmin && isCompta"> <a style="cursor: pointer;" (click)="comptabilise(row)"
                          title="Comptabiliser"><i class="icon-layers"></i></a>
                      </td><!--
                      <td *ngIf="isAdmin"> <a style="cursor: pointer;" (click)="journalise(row)"
                          title="Journalisation de relevé bancaire"><i class="bi bi-bank"></i></a>
                      </td>-->
                      <td *ngIf="isAdmin">
                        <a style="cursor: pointer;" (click)="deletedoc(row)" title="Supprimer"><i
                            class="icon-trash"></i> </a>
                      </td>
                    </tr>
                  </table>

                </td>
              </ng-container>

              <tr class="mat-row" *matNoDataRow>
                <!-- <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td> -->
                <td class="mat-cell" colspan="4">Aucun résultat pour le filter "{{input.value}}"</td>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 500]" [length]="dataSource.data.length" [pageSize]="5"
              showFirstLastButtons></mat-paginator>
          </div>
        </mat-card-content>
        <mat-card-footer>
          <table class="w-100">
            <tr>
              <td *ngFor="let elt of columnTotal">
                Total {{elt.name}} : <b>{{elt.position | number:'1.2-2':'fr' }} </b>
              </td>
            </tr>
          </table>
        </mat-card-footer>
      </mat-card>

    </div>
  </div>
</div>