import { HttpClient } from '@angular/common/http';
import { Injectable, ViewChild, TemplateRef } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Utilisateur } from '../model/utilisateur';
import { AuthService } from './auth.service';

@Injectable()
export class UtilisateurService extends AuthService {
    constructor(public http: HttpClient,
        public oauthService: OAuthService) {
        super(oauthService, http);
    }

    create(user: Utilisateur,authUser:any): Observable<Utilisateur> {
        return this.http.post<Utilisateur>(environment.backendUrl + 'utilisateur/create', user, this.httpOptions);
    }

    getAllUsers() : Observable<Utilisateur[]>{
        return this.http.get<Utilisateur[]>(`${environment.backendUrl}${environment.allUsers}`, this.httpOptions);
     }
}

