export class Bread {
  id: string;
  name: string;
  code: string;
  children: Bread[] = [];
  constructor(id: string,
    name: string, code: string, children: Bread[]) {
    this.id = id;
    this.name = name;
    this.code = code;
    children = children;
  }
}
