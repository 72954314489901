
  <h6>Classement</h6>
    <div class="row">
        <select class="custom-select" id="inputGroupSelect01" [(ngModel)]="valeur" (change)="setDossiersLev2($event.target.value)">
                <option selected value="-1" selected>Choisir...</option>
                <option *ngFor="let item of listDossiersLev1" [value]="item.dossierId" >{{item.name}}</option>
        </select>
    </div>
       <div class="row" *ngIf="valeur!=undefined || listDossiersLev2==undefined">
        <select class="custom-select" id="inputGroupSelect02" [(ngModel)]="valeur2"  (change)="setDossiersLev3($event.target.value)">
                <option selected>Choisir...</option>
                <option *ngFor="let ite of listDossiersLev2" [value]="ite.dossierId">{{ite.name}}</option>
        </select>
    </div>
    <div class="row" *ngIf="valeur2!=undefined  || listDossiersLev3==undefined">
            <select class="custom-select" id="inputGroupSelect03" [(ngModel)]="valeur3"  (change)="setDossiersLev4($event.target.value)">
                    <option selected>Choisir...</option>
                    <option *ngFor="let it of listDossiersLev3" [value]="it.dossierId">{{it.name}}</option>
            </select>
    </div>
    <h6>Propriétés</h6>
    
<div class="row " *ngIf="valeur2!=undefined">
      
            <select class="custom-select" id="inputGroupSelect03" [(ngModel)]="metaIndex" >
                    <option selected>Choisir...</option>
                    <option *ngFor="let meta of listMetadata; let i=index" [value]="i">{{meta.key}}</option>
            </select>
    </div>
 
   

    <div class="row">
            <div class="input-group">

            <!--        <input type="text" class="form-control"  ([ngModel])="vMetaData">dsds
            <ng2-completer [datasource]="metaData" [minSearchLength]="2"></ng2-completer>
                 -->   <div class="input-group-append">
                        <input type="text" class="form-control" [(ngModel)]="vMetaData">
 
 <!--<button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <div class="dropdown-menu">
                        <button class="dropdown-item" (click)="setOpe(1)">égale</button>
                        <button class="dropdown-item" (click)="setOpe(2)">différent</button>
                        <button class="dropdown-item" (click)="setOpe(3)">comme</button>
                      </div>-->
                      <select name="operator" id="operator" (change)="setOpe($event.target.value)">
                          <option value="=" >égale</option>
                          <option value="<>" >différent</option>
                          <option value="like" >contient</option>
                      </select>
                      <button type="button" class="btn btn-primary" (click)="setRequest()">Ok</button>
                    </div>
                  </div>
    
    </div>