import { PropertyValue } from "./propertyvalue";

export class Document{
    documentuniqueid: string;
   properties: PropertyValue[];
   dossierId: number;
   codification: string;
   constructor(dossierId){
       this.properties=[];
       this.dossierId=dossierId;
   }
   add(property : PropertyValue){
        this.properties.push(property);
   }
}

export class NewDocument {
    documentuniqueid: string;
   properties: PropertyValue[];
   dossierId: number;
   codification: string;
   constructor(dossierId){
       this.properties=[];
       this.dossierId=dossierId;
   }
   add(property : PropertyValue){
        this.properties.push(property);
   }
}