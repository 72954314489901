import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DossierService } from 'src/app/shared/services/dossier.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-merge',
  templateUrl: './merge.component.html',
  styleUrls: ['./merge.component.scss']
})
export class MergeComponent implements OnInit {
  dossierId: number;
  fileUrl: string;
  fileBlob: any;
  viewer: string;

  constructor(private dossierService: DossierService,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public data: JSON[]) { 
      this.dossierId = data['dossierId'];
  }

  ngOnInit(): void {
    this.getFile(this.dossierId);
  }

  getFile(docId: number) {
    this.dossierService.merge(docId).subscribe(res => {
      console.log("res",res)
      if (res == null) {
        this.fileUrl = "";
      } else {
       
        this.fileBlob = this.fileUploadService.dataURItoBlob(res["message"], "application/pdf");
        this.fileUrl = window.URL.createObjectURL(this.fileBlob);
          this.viewer = "pdf";
      }
    },
      error => {
        this.viewer = "error";
        console.log("error",error);
      })
  }
}
