<h1 mat-dialog-title>Envoi du fichier par mail</h1>
<div mat-dialog-content>
  <form [formGroup]="mailGroup">
    <div class="form-group">
      <label for="exampleFormControlInput1">Email du destinataire: </label>
      <input type="email" formControlName="to" class="form-control">
    </div>
    <div class="form-group">
        <label for="exampleFormControlInput1" id="labelsubject" >Objet: </label>
        <input type="text" formControlName="subject" class="form-control" id="subject">
      </div>
      <div class="form-group">
        <label for="exampleFormControlInput1" id="labelmessage" >Message</label>
        <!-- <textarea  class="form-control" id="message" [(ngModel)]="newMail.message"></textarea> -->
        <ck-editor formControlName="message" name="editor1" skin="moono-lisa" language="fr" [fullPage]="true"></ck-editor>
      </div>

  </form>
    
    <!-- <div class="form-group">
      <label for="exampleFormControlSelect1">Dossier parent</label>
      <select class="form-control"  id="parentid" [(ngModel)]="dossier.dossierId">
        <option *ngFor="let dos of listDossiers; let i=index" [value]="dos.dossierId">{{dos.name}}</option>
      </select>
    </div>       -->

</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!mailGroup.valid" (click)="send()" tabindex="2" *ngIf="!sending">Envoyer</button>
  <button mat-raised-button color="primary"  tabindex="2" *ngIf="sending">Envoi en cours ...</button>
  <button mat-raised-button color="warn" (click)="close()" tabindex="-1">Fermer</button>
</div>
