import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Reference } from '../model/reference';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class ReferenceService {
    constructor(private http: HttpClient) { }

    /**
     * 
     * @param level 
     * @returns 
     */
    getListReference(level: number): Observable<Reference[]> {
        return this.http.get<Reference[]>(`${environment.backendUrl}reference/level/${level}`);
    }

    getListReferenceByParentName(name: string): Observable<Reference[]> {
        return this.http.get<Reference[]>(`${environment.backendUrl}reference/children/${name}`);
    }

    /**
     * 
     * @param reference 
     * @returns 
     */
    createReference(reference: Reference) {
        return this.http.post<Reference>(`${environment.backendUrl}reference/save`, reference);
    }

    /**
     * 
     * @param refId 
     */
    removeReference(refId: number) {
        return this.http.delete<string>(`${environment.backendUrl}reference/delete/${refId}`);
    }

    /**
    * 
    * @param level 
    * @returns 
    */
    getReferenceChildren(refId: number): Observable<Reference[]> {
        return this.http.get<Reference[]>(`${environment.backendUrl}reference/child/${refId}`);
    }

}