import { Injectable, ViewChild, TemplateRef } from '@angular/core';
import { DossierService } from './dossier.service';
import { MetaData } from '../model/metadata';
import { RequestItem } from '../model/request.item';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class RequestService{
  

  public requestValue: RequestItem[]=[];
  public request: string[]=[];
  public requestResult: MetaData[][]=[];
  public requestHead: MetaData[]=[];
  public strRequestResult: string;
  public strRequestHead: string;
  sp = " ";
  public requestDetail: JSON[]=[];
  columns: any[]=[];
  displayColumns: any[] = [];
  data: JSON[]=[];
  @ViewChild('roleTemplate') roleTemplate: TemplateRef<any>;
  title: string[]=[];
  subtitle: string[]=[];
  constructor(private dossierService: DossierService, private http: HttpClient ){

  }
  setRequest(rVal: string,uVal: string,ope: string){
      this.requestValue.push(new RequestItem(rVal,uVal,ope));
  }
  public addRequestString(uVal: string,bOpe: string,value: string, ope: string,field: string){
    var req1: string = "dossier.key = "+this.sp+""+field+"";
    console.log(req1);
    if(ope.toUpperCase()=='LIKE'){
      var req2: string = "dossier.value"+ this.sp +ope+this.sp+"%"+value+"%";
      var uReq = uVal+this.sp+ope+this.sp+""+value+"";
      console.log(uReq);
    }else {
      var req2: string = "dossier.value"+ this.sp +ope+this.sp+""+value+"";
      var uReq = uVal+this.sp+ope+this.sp+""+value+"";
    }
    if(this.request.indexOf(this.sp+req1+this.sp+"AND"+this.sp+req2)<0)
       this.requestValue.push(new RequestItem(this.sp+req1+this.sp+"AND"+this.sp+req2,uReq,bOpe)); 
       this.request.push(this.sp+req1+this.sp+"AND"+this.sp+req2);
  }

  searchResult(idDossier : any,query:any):Observable<any> {
      return this.http.post(`${environment.backendUrl}document/query/${idDossier}`,query);
  }
  constructResult(resMetas: MetaData[],resMeta: MetaData[]): MetaData[][] {
    var nbCols: number = (resMeta.length>0)?resMetas.length/resMeta.length:0;
    var meta: MetaData[][]=[];
    var index=0;
     var j=0;meta[j]=[];
     var text = '{'
    for (var i=0;i<resMetas.length;i++){
      if (j<nbCols-1){
        meta[index].push(resMetas[i]);j++;
        text+='"'+resMetas[i].field+'":"'+resMetas[i].value+'",';
      }else {
        meta[index].push(resMetas[i])
        text+='"'+resMetas[i].field+'":"'+resMetas[i].value+'"}';
        this.data.push(JSON.parse(text));text = '{'
        j=0;
        index++;meta[index]=[];
      }
      
    }
   meta.pop();
   if(meta!=undefined){
      for (var i=0;i<meta.length;i++){
           this.requestResult.push(meta[i]);
    }
    }
    console.log(this.data);
   return meta;
  }
  getAllMetaSearch(resMeta: MetaData[]): MetaData[] {
    var resMetas: MetaData[]=[];
    for(var k=0;k<resMeta.length;k++){
      resMetas = resMetas.concat(this.dossierService.metadatas.filter(function(meta){ 
        return (meta.fk_obj_id==resMeta[k].fk_obj_id);
        }));
      }
      return resMetas;
  }
  getMetaSearch(req: string[]): MetaData[] {
    return this.dossierService.metadatas.filter(function(meta){ 
      return (meta.field==req[2]) && (meta.value==req[6]);
      })
  }

  searchResultAll(arg0: string,user:any): Observable<any> {
    return this.http.post(`${environment.backendUrl}document/search/${arg0}`,user);

  }

}
