<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Liste des dossiers</h6>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Libellé</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let dos of listDossiers; let i=index" >
                        <td>{{dos.libel}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<app-tree></app-tree>
                    
