
<!-- 2nd Level Menu -->
<ul class="sidebar-submenu" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }" *ngIf="menuItem.children">
        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
          <!-- Sub -->
          <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
            <i class="fa fa-circle"></i>{{childrenItem.title}} <span
              class="badge badge-{{childrenItem.badgeType}} pull-right"
              *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- Link -->
          <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <i class="fa fa-circle"></i>{{childrenItem.title}} <span
              class="badge badge-{{childrenItem.badgeType}} pull-right"
              *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- External Link -->
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <i class="fa fa-circle"></i>{{childrenItem.title}} <span
              class="badge badge-{{childrenItem.badgeType}} pull-right"
              *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- External Tab Link -->
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
            *ngIf="childrenItem.type === 'extTabLink'">
            <i class="fa fa-circle"></i>{{childrenItem.title}} <span
              class="badge badge-{{childrenItem.badgeType}} pull-right"
              *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- 3rd Level Menu -->
          <app-submenu [menuItem]="childrenItem"></app-submenu>

        </li>
      </ul>