import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Dossier } from 'src/app/shared/model/dossier';
import { DossierService } from 'src/app/shared/services/dossier.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-move-doc',
  templateUrl: './move-doc.component.html',
  styleUrls: ['./move-doc.component.scss']
})
export class MoveDocComponent implements OnInit {
  docsSelected: any;
  dossierId: number;
  treeNode: Dossier[] = [];
  currrentDossier: Dossier = null;
  indexElk: string;
  @Output() onModaleClose: EventEmitter<string> = new EventEmitter();

  constructor(private dossierService: DossierService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<MoveDocComponent>
  ) { }

  ngOnInit(): void {
    this.docsSelected = this.data.data;
    this.dossierId = this.data.dossId;
    this.indexElk = `dossier${this.dossierId}`;
    this.getFile();
  }

  toggleChild(node: Dossier) {
    node.expand = !node.expand;
  }

  getFile() {
    this.dossierService.getArbo(this.dossierId).subscribe(arbo => {
      console.log(arbo);
      this.treeNode.push(arbo);
    },
      error => {

      })
  }
  reclasser() {
    this.SpinnerService.show();
    this.dossierService.moveDossierTo(this.docsSelected, this.indexElk, this.currrentDossier.dossierEncoded)
      .subscribe(result => {
        this.SpinnerService.hide();
        this.onModaleClose.emit(this.currrentDossier.dossierEncoded);

      }, err => {

        this.SpinnerService.hide();
      })
  }
  cancel() {
    this.dialogRef.close();
  }
  selectNode(node: Dossier) {
    if (this.currrentDossier == null) {
      this.currrentDossier = node;
      this.currrentDossier.selected = true;
      return;
    }
    if (this.currrentDossier.dossierId != node.dossierId) {
      this.currrentDossier.selected = false;
    }
    node.selected = !node.selected;
    this.currrentDossier = node;
  }



}
