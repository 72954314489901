
    <form [formGroup]="form" (ngSubmit)="uploadFile()">

        <!-- Progress Bar -->
        <div class="progress form-group" *ngIf="progress > 0">
          <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
          </div>
        </div>
        <div *ngIf="sizeError">{{errorMessage }} {{fileSize/1024000}}</div>
        <div class="form-group">
          <br> <br> <h2>{{useMessage}}</h2><br><br>
          <input type="file" name="fileInput" (change)="selectFile($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  />
  
        </div>
  
        <div class="form-group">
          <button class="btn btn-primary btn-block btn-lg">Importer</button>
        </div>
      </form>
