import { Injectable } from "@angular/core";
import { OAuthService, NullValidationHandler, AuthConfig, UserInfo } from "angular-oauth2-oidc";
import { environment } from "../../../environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EmailService } from "./email.service";

@Injectable()
export class AuthService {
  httpOptions: {};
  public user = { name: null, email: null, roles: [], login: null };
  constructor(public oauthService: OAuthService,
    public http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + oauthService.getAccessToken()
      })
    }
  }
  async initConfiguration() {
    return new Promise((resolveFn, rejectFn) => {
      this.configure(resolveFn, rejectFn);

    })

  }
  authConfig: AuthConfig = {

    issuer: environment.issuer,
    redirectUri: environment.redirectUri,
    clientId: 'vusadoc-frontend',
    //scope: 'openid profile email role web-origins',
    responseType: 'code',
    // at_hash is not present in JWT token
    disableAtHashCheck: true,
    showDebugInformation: true
  }
  private configure(resolveFn, rejectFn) {
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    //this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.loadDiscoveryDocumentAndLogin().then(isLoggedIn => {
      if (isLoggedIn) {
        this.oauthService.setupAutomaticSilentRefresh();
        const claims = this.getClaims();
        this.user.name = claims.name;
        this.user.email = claims.email;
        this.user.login = claims.login;
        this.user.roles = claims.resource_access['vusadoc-frontend'].roles
        resolveFn(null);
      } else {
        this.oauthService.initImplicitFlow();
        rejectFn();
      }
      environment.access_token = this.oauthService.getAccessToken();
      //console.log(this.oauthService.getAccessToken());
    })
  }
  public login() {
    this.oauthService.initLoginFlow();
  }

  /**
   * Is connected
   * @returns 
   */
  isLoggedIn() {
    return this.oauthService.getAccessToken() != null;
  }
  /**
   * Has Role
   * @param roleName 
   * @returns 
   */
  hasRole(roleName: string) {
    return this.user.roles.find(r => r === roleName);
  }
  public logoff() {
    this.oauthService.logOut();
  }
  // public loadUserProfile(): Promise<any> {
  //      console.log("loadUserProfile")
  //    return this.oauthService.loadUserProfile().then((userData: any) => {
  //     console.log("userData",userData) 
  //     this.user.name = userData.name;
  //      this.user.email = userData.email;
  //      console.log(this.oauthService.getIdentityClaims());
  //      console.log(this.oauthService.getGrantedScopes());
  //      this.user.roles = th
  //      console.log("user",this.user);
  //    }); 
  // }
  public getUserRoles(url: string): Observable<any> {
    return this.http.get<any>(url, this.httpOptions);
  }


  getClaims() {
    const token = this.oauthService.getAccessToken();
    let payloads: string[] = token.split('.');
    const claims = JSON.parse(atob(payloads[1]));
    console.log(claims);
    return claims;
  }
}