export class MailMessage{
    from: string;
    to: string;
    subject: string;
    message: string;
    fileToSend: string;
    filename: string;
    constructor(){
        
    }
}