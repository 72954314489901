import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { content } from "./shared/routes/content-routes";
import { AuthGuardService } from './shared/services/auth-guard.service';
import { Error403Component } from './shared/components/error403/error403.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/user/home',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    redirectTo: '/admin/home',
    pathMatch: 'full',
    //canActivate: [AuthGuardService]
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content
  },
  {
    path: 'admin',
    component: ContentLayoutComponent,
    children: content
  },
  {
    path: 'page-403',
    component: Error403Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
