
qsdqsdqsqsdsqssdsq

<div style="display: block" class="w-100" >
    <canvas baseChart
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType" class="w-100">
    </canvas>
  </div>
