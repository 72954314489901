import { Component, OnInit } from '@angular/core';
import Dynamsoft from 'dwt';
import { WebTwain } from 'dwt/dist/types/WebTwain';

@Component({
  selector: 'app-scan-view',
  templateUrl: './scan-view.component.html',
  styleUrls: ['./scan-view.component.scss']
})
export class ScanViewComponent implements OnInit {
  containerId = "dwtcontrolContainer";
  constructor() { }

  ngOnInit(): void {
    this.initDynamSoft();
  }

   //These tree following functions handle scanning possibilities
initDynamSoft(){
  Dynamsoft.DWT.Containers = [{
    WebTwainId: 'dwtObject',
    ContainerId: this.containerId,
    Width: '300px',
    Height: '400px'
}];
Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
    this.Dynamsoft_OnReady();
});
Dynamsoft.DWT.ProductKey = "t0151KQMAACouLnnfvoi1DSjbe9dy74bYZjVFoEqjrniigJ7a1vJeJFM++3vGZsR2i6iRsMEcU3KPJ7n2/VMTG2Rn08nH4vISbKPoGMQiQu6UimuMXmYthtXgpc9tyqw3s+H6GPRpjE4Y2qbsZ2y6mf871H7m1uiEoW26mRvT33NIQ9xByF4zy8johKFtauancXSeCPEERe6mmw==";
Dynamsoft.DWT.ResourcesPath = '/assets/';
Dynamsoft.DWT.Load();
}

//Use the method Dynamsoft.DWT.GetWebTwain() to get a handler of the created WebTwain instance.
DWObject: WebTwain = null;
Dynamsoft_OnReady() {
    this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
}

acquireImage() {
  this.DWObject.AcquireImage();
}


}
