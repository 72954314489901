import { Injectable } from "@angular/core";
import { MailMessage } from "../model/mail.message";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class EmailService{
    constructor(private http: HttpClient){
        
    }
  sendMail(email:MailMessage):Observable<String>{
      return this.http.post<String>(`${environment.backendUrl}${environment.document}${environment.emailUrl}`,email);
  }
}