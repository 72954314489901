<ul *ngIf="treeNode">
    <div *ngFor="let node of treeNode" class="procedure-hierarchy">
        <li class="list-group-item">
            <div class="group-item">
                <div class="list-group-item-icon" (click)="toggleChild(node)">
                    <span class="folder-icon" *ngIf="!node.expanded || !node.node.children"><i
                            class="fa fa-folder"></i></span>
                    <span class="folder-open-icon" *ngIf="node.expanded && node.node.children"><i
                            class="fa fa-folder-open"></i></span>
                    <span> {{ node.name }}</span>
                </div>
                <div class="classCheck" *ngIf="node.properties.length>0 && !node.dataOnly"><input type="checkbox"
                        (change)="selectNode(node)" [checked]="node.selected"></div>
            </div>

            <app-tree-view *ngIf="node.expand" [treeNode]="node.children" (selectNodeEvent)="selectNode($event)">
            </app-tree-view>
        </li>

    </div>
</ul>