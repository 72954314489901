
<div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
            <table *ngIf="listProperty.length>0" class="w-100">
                    <thead>
                        <th >
                           Libellé
                        </th>
                        <th>
                               Action
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let prop of listProperty">
                                <td>{{prop.key}}</td>
                                <td>
                                    <div style="cursor: pointer;" class="float-right z-index-1000 " (click)="modifPropriete(prop)">
                                    <button placement="left"  [ngbTooltip]="'Modifier '+prop.key"  mat-button mat-icon-button >
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    </div>
                                </td>
                        </tr>
                    </tbody>
                </table>
    </div>
    <!--<div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="add()">Ajouter</button>
    </div>-->
    







