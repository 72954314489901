import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dossier } from 'src/app/shared/model/dossier';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit {
  @Input()
  treeNode: Dossier[];
  @Output()
  selectNodeEvent = new EventEmitter<Dossier>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleChild(node: Dossier) {
    node.expand = !node.expand;
  }

  selectNode(node: Dossier): void {
    this.selectNodeEvent.emit(node);
  }

}
