

<div class="btn-toolbar btn-block row" role="toolbar"> 
    <div class="col-6">
        <app-left-side-bar></app-left-side-bar>
    </div> 
    <div class="col-6">
      <h6><span class="badge badge-pill badge-secondary" *ngFor="let item of requestValue; let i=index">{{item.userString}}<span class="badge badge-pill badge-secondary" (click)="delete(i)">X</span></span></h6> 
     </div> 
  </div>
  
  
  <div class="btn-toolbar btn-block row mt-3" role="toolbar">
  <div class="col-12">
  <hr>
      <div class="input-group-prepend mb-1">
          <button class="btn btn-secondary mr-3" (click)="launch()">Enregistrer</button>
          <button class="btn btn-primary" (click)="launch()">Rechercher</button>
       </div>
  
  </div>         
  </div>

  
  
  