<div class="col-12">
<mat-form-field appearance="fill">
  <mat-label>Composantes</mat-label>
  <mat-select [(ngModel)]="selectedComp" (selectionChange)="setDataSource()">
    <mat-option *ngFor="let comp of columnList" [value]="comp">
      {{comp.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
</div>
<div class="col-12">
  <div *ngFor="let comp of columnList, let index=index" class="col-2">
  <mat-form-field  *ngIf="comp!=selectedComp" >
  <mat-label>{{comp.name}}</mat-label>
  <mat-select [(ngModel)]="filterReport[index]" (selectionChange)="filterDataSource()" >
    <mat-option *ngFor="let elt of comp.list.listValues" [value]="{'name':comp.name,'value':elt.value,'prop':comp.prop}">
      {{elt.value}}
    </mat-option>
  </mat-select>
</mat-form-field>
</div>
</div>
<hr>
<mat-grid-list cols="2" rowHeight="50px">
  <mat-grid-tile>
    <div class="w-100">
      <mat-form-field appearance="fill">
          <mat-label>Axes</mat-label >
          <mat-select [(ngModel)]="barChart.selectedAxe">
            <mat-option *ngFor="let axe of columnTotal" [value]="axe">
              {{axe.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="barChart.execute(selectedComp,filteredDataSource)">Exécuter</button>
      </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="w-100">
      <mat-form-field appearance="fill">
          <mat-label>Axes</mat-label >
          <mat-select [(ngModel)]="doughnutChart.selectedAxe">
            <mat-option *ngFor="let axe of columnTotal" [value]="axe">
              {{axe.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="doughnutChart.execute(selectedComp,filteredDataSource)">Exécuter</button>
      </div>
  </mat-grid-tile>
    <mat-grid-tile rowspan="8">
        <div class="w-100"> 
        <mat-spinner *ngIf="barChart.spinner"></mat-spinner>
        <div style="display: block" class="w-100" *ngIf="barChart.showChart">
            <canvas baseChart
                    [datasets]="barChart.barChartData"
                    [labels]="barChart.barChartLabels"
                    [options]="barChart.barChartOptions"
                    [legend]="barChart.barChartLegend"
                    [chartType]="barChart.barChartType" class="w-75">
            </canvas>
          </div> 
          <div style="display: block" class="w-100" *ngIf="barChart.showChart">
          <table class="w-100">
            <thead>
                <tr><th *ngFor="let v of barChart.arrayValues">{{v.name}}</th></tr>
            </thead>
            <tbody>
                <tr><td *ngFor="let v of barChart.arrayValues">{{v.value | number:'1.2-2':'fr'}}</td></tr>
            </tbody>
        </table>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile rowspan="8">
      <div class="w-100"> 
      <mat-spinner *ngIf="doughnutChart.spinner"></mat-spinner>
      <div style="display: block" class="w-100" *ngIf="doughnutChart.showChart">
          <canvas baseChart
                      [datasets]="doughnutChart.doughnutChartData"
                      [labels]="doughnutChart.doughnutChartLabels"
                      [chartType]="doughnutChart.doughnutChartType" class="w-75"></canvas>
        </div>
        <div style="display: block" class="w-100" *ngIf="doughnutChart.showChart">
        <table class="w-100">
          <thead>
              <tr><th *ngFor="let v of doughnutChart.arrayValues">{{v.name}}</th></tr>
          </thead>
          <tbody>
              <tr><td *ngFor="let v of doughnutChart.arrayValues">{{v.value | number:'1.2-2':'fr'}}</td></tr>
          </tbody>
      </table>
      </div>
      </div>
    </mat-grid-tile>
   
    <mat-grid-tile>
      
      <div class="w-100">
        <mat-form-field appearance="fill">
            <mat-label>Axes</mat-label >
            <mat-select [(ngModel)]="pieChart.selectedAxe">
              <mat-option *ngFor="let axe of columnTotal" [value]="axe">
                {{axe.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button color="primary" (click)="pieChart.execute(selectedComp,filteredDataSource)">Exécuter</button>
        </div>

    </mat-grid-tile>
    <mat-grid-tile>
      <div class="w-100">
        <mat-form-field appearance="fill">
            <mat-label>Axes</mat-label >
            <mat-select [(ngModel)]="radarChart.selectedAxe">
              <mat-option *ngFor="let axe of columnTotal" [value]="axe">
                {{axe.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button color="primary" (click)="radarChart.execute(selectedComp,filteredDataSource)">Exécuter</button>
        </div>
    </mat-grid-tile>
    <mat-grid-tile rowspan="8">
      
      <div class="w-100"> 
        <mat-spinner *ngIf="pieChart.spinner"></mat-spinner>
        <div style="display: block" class="w-100" *ngIf="pieChart.showChart">
            <canvas baseChart
                        [datasets]="pieChart.pieChartData"
                        [labels]="pieChart.pieChartLabels"
                        [chartType]="pieChart.pieChartType" class="w-75"></canvas>
          </div>
          <div style="display: block" class="w-100" *ngIf="pieChart.showChart">
          <table class="w-100">
            <thead>
                <tr><th *ngFor="let v of pieChart.arrayValues">{{v.name}}</th></tr>
            </thead>
            <tbody>
                <tr><td *ngFor="let v of pieChart.arrayValues">{{v.value | number:'1.2-2':'fr'}}</td></tr>
            </tbody>
        </table>
        </div>
        </div>


    </mat-grid-tile>
    <mat-grid-tile rowspan="8">
      
      <div class="w-100"> 
        <mat-spinner *ngIf="radarChart.spinner"></mat-spinner>
        <div style="display: block" class="w-100" *ngIf="radarChart.showChart">
            <canvas baseChart
                        [datasets]="radarChart.radarChartData"
                        [labels]="radarChart.radarChartLabels"
                        [chartType]="radarChart.radarChartType" class="w-75"></canvas>
          </div>
          <div style="display: block" class="w-100" *ngIf="radarChart.showChart">
          <table class="w-100">
            <thead>
                <tr><th *ngFor="let v of radarChart.arrayValues">{{v.name}}</th></tr>
            </thead>
            <tbody>
                <tr><td *ngFor="let v of radarChart.arrayValues">{{v.value| number:'1.2-2':'fr'}}</td></tr>
            </tbody>
        </table>
        </div>
        </div>


    </mat-grid-tile>

  </mat-grid-list>
