import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { LeftSideBarComponent } from './left-side-bar/left-side-bar.component';
import { ObjectDetailComponent } from './object-detail/object-detail.component';
import { PdfViewComponent } from './pdf-view/pdf-view.component';
import { TimelineComponent } from './timeline/timeline.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserSpaceRoutingModule } from './user-space.routing';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneConfigInterface, DROPZONE_CONFIG, DropzoneModule } from 'ngx-dropzone-wrapper';
//import { AgGridModule } from 'ag-grid-angular';
import { MaterialModule } from '../material.module';
import { TabResultComponent } from './tab-result/tab-result.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from "../shared/shared.module";
import { CountToModule } from 'angular-count-to';
import { SearchAllFieldsComponent } from './search-all-fields/search-all-fields.component';
import { SendFileComponent } from './send-file/send-file.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { FlexLayoutModule } from "@angular/flex-layout";
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from "ngx-spinner";
//import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';

import { ChatComponent } from './chat/chat.component';
import { TabRapportComponent } from './tab-rapport/tab-rapport.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { RadarChartComponent } from './radar-chart/radar-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { PrintViewComponent } from './print-view/print-view.component';
import { ScanViewComponent } from './scan-view/scan-view.component';
import { DwtComponent } from './dwt/dwt.component';
import { DataViewerComponent } from './data-viewer/data-viewer.component';
import { VisualizeComponent } from './visualize/visualize.component';
import { MoveDocComponent } from './move-doc/move-doc.component';
import { TreeViewComponent } from './move-doc/tree-view/tree-view.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { UppyAngularModule } from 'uppy-angular';
import { UploadComponent } from './upload/upload.component';
import { ObjectDataDetailComponent } from './object-data-detail/object-data-detail.component';
import { MergeComponent } from './merge/merge.component';
import { OperationComponent } from './operation/operation.component';
import { SyntheseComponent } from './synthese/synthese.component';
import { WidgetsComponent } from './widgets/widgets.component';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: '*'
};
@NgModule({
  declarations: [SearchComponent,
    LeftSideBarComponent,
    ObjectDetailComponent,
    TimelineComponent,
    PdfViewComponent,
    TabResultComponent,
    HomeComponent,
    SearchAllFieldsComponent,
    ChatComponent,
    SendFileComponent,
    TabRapportComponent,
    BarChartComponent,
    RadarChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    PrintViewComponent,
    ScanViewComponent,
    DwtComponent,
    DataViewerComponent,
    VisualizeComponent,
    MoveDocComponent,
    TreeViewComponent,
    UploadComponent,
    ObjectDataDetailComponent,
    MergeComponent,
    OperationComponent,
    SyntheseComponent,
    WidgetsComponent],
  imports: [
    CommonModule, UserSpaceRoutingModule,
    PdfViewerModule, FormsModule,
    NgxDatatableModule, NgxPageScrollModule,
    NgbModule, DropzoneModule, UserSpaceRoutingModule,
    ReactiveFormsModule, MaterialModule, NgxDocViewerModule,
    SharedModule, CountToModule,
    FlexLayoutModule,
    CKEditorModule,
    ChartsModule,
    NgxSpinnerModule,
    NgxQRCodeModule,
    UppyAngularModule,
    //NgxIntlTelInputModule,
  ],
  entryComponents: [SendFileComponent, PrintViewComponent, ScanViewComponent, DwtComponent, VisualizeComponent, OperationComponent],
  providers: [{
    provide: DROPZONE_CONFIG,
    useValue: DEFAULT_DROPZONE_CONFIG
  }]
})
export class UserSpaceModule { }
