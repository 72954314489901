<ul class="list-group treeview">
    <li *ngFor="let doss of listDossiers" class=" node-treeview-searchable " data-nodeid="0">
        <div [class]="doss.class">
            <span class="indent" *ngIf="doss.parentId!=0"></span>
            <span class="fa fa-folder-o" *ngIf="doss.children.length>0 && !doss.expand"
                (click)="doss.expand=!doss.expand">

            </span>
            <span class="fa fa-folder-open-o" *ngIf="doss.children.length>0 && doss.expand "
                (click)="doss.expand=!doss.expand">
            </span>
            <span class="icon node-icon" *ngIf="doss.children.length==0"></span>
            <span><a (click)="doss.expand=!doss.expand"
                    [routerLink]="'/user/search/'+doss.dossierEncoded">&nbsp;{{doss.name}}</a></span>
        </div>
        <div class="pl-10">
            <app-wtree [listDossiers]="doss.children" *ngIf="doss.expand" (dossEmitter)="onClick($event)"></app-wtree>
        </div>
    </li>
</ul>