<div class="card">
    <div class="card-header">
        <h2>Nouvel utilisateur</h2>
    </div>
    <div class="card-body">
        <form [formGroup]="formGroup" class="container-fluid" (submit)="create()">
            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <mat-label>Pr&eacute;nom</mat-label>
                    <input matInput formControlName="firstName">
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <mat-label>Nom</mat-label>
                    <input matInput formControlName="lastName">
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <mat-label>Nom d'utilisateur</mat-label>
                    <input matInput formControlName="username">
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <mat-label>Nom de l'espace client</mat-label>
                    <input matInput formControlName="enterprise">
                </mat-form-field>
            </div>
            
            <div class="form-group">
                <mat-slide-toggle formControlName="enabled">Actif</mat-slide-toggle>
            </div>
            <div class="card-footer">
                <button class="mr-5" [disabled]="!formGroup.valid" mat-stroked-button type="submit" color="primary">Enregistrer</button>
                <button mat-stroked-button color="accent" (click)="closeDialog()">Annuler</button>
            </div>
        </form>
    </div>
</div>


