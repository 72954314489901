import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dossier } from '../../model/dossier';

@Component({
  selector: 'app-wtree',
  templateUrl: './wtree.component.html',
  styleUrls: ['./wtree.component.scss']
})
export class WtreeComponent implements OnInit {
  @Input() listDossiers: Dossier[];
  @Output() dossEmitter: EventEmitter<Dossier> = new EventEmitter<Dossier>();
  expand: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  onClick(doss: Dossier) {
    this.unselected(this.listDossiers);
    doss.class = "node-selected"
    this.dossEmitter.emit(doss);
  }

  unselected(listDossiers: Dossier[]) {

    listDossiers.forEach(element => {
      element.class = "node-unselected";
      this.unselected(element.children);
    });
  }

}
