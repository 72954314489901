import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { SearchResult } from "../model/searchResult";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Dossier } from "../model/dossier";
import { OAuthService } from "angular-oauth2-oidc";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ElkService extends AuthService{
  textSearchResult: SearchResult[] = [];;
  constructor(public http: HttpClient,public oauthService: OAuthService) {
    super(oauthService, http);
  }
  searchText(searchInput) {
    let url = environment.elk_url + "_search";
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return new Promise((resolve, reject) => {
      this.http.post(url, { "query": { "match": { "attachment.content": searchInput } } }, { headers: headers }).toPromise()
        .then(data => {
          this.textSearchResult.push(data as SearchResult);
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    });
  }
  getFile(index: string, docId: string): Observable<JSON> {
    let url = environment.backendUrl + "elkle/" + index + "/api/" + docId;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get<JSON>(url);
  }
  uploadFile(fileBase64, index, docID) {
    let url = environment.elk_url + environment.index_pref + index + "/_doc/" + docID + "?pipeline=attachment";

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    //headers.append('Accept', 'application/json');

    return new Promise((resolve, reject) => {
      this.http.post(url, { "data": fileBase64 }, { headers: headers })
        .toPromise()
        .then(
          data => {
            resolve(data)
          })
        .catch(
          error => {
            console.error(error);
            reject(error);
          }
        )
    });
  }

  uploadExcelFile(file, endPoint: string) {

    let headers = new HttpHeaders();
    var formData: any = new FormData();
    formData.append("file", file)
    return this.http.post(endPoint, formData, {
      headers: this.httpOptions,
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )

  }


  uploadFile2(fileBase64, index, docID): Observable<any> {
    //let url = environment.elk_url +environment.index_pref +index+"/_doc/" + docID + "?pipeline=attachment";
    let url = environment.backendUrl + "elkle/" + index + "/api/" + docID + "/upload/";

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.post(url, fileBase64, {
      headers: headers,
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}