import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ChatService } from '../../shared/services/chat.service';
import { ChatUsers, Messages } from '../../shared/model/chat.model';
import { AuthService } from '../../shared/services/auth.service';
import { DocumentService } from '../../shared/services/document.service';
import { interval, Subscription } from 'rxjs';

var today = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  
  public openTab : string = "call";
  public users : ChatUsers[] = []
  public searchUsers : ChatUsers[] = []
  public chatUser : any
  public profile : any
  public chats : any
  public chatText : string;
  public error : boolean = false
  public notFound: boolean=false
  public id : any;
  public searchText : string
  @Input() docId: string;
  userAuth: any;
  subscription: Subscription;
  intervalId: number;
  source = interval(15000);
  messLength: number = 0;
  constructor(private chatService: ChatService, public authService: AuthService,private documentService: DocumentService) {   
    this.chatService.getUsers().subscribe(users => { 
      this.searchUsers = users
      this.users = users;
      
    })
  }

  ngOnInit() {  
    this.userAuth = this.authService.user;
    console.log("user auth", this.userAuth);
    // this.userChat(this.authService.user.email)
     this.getProfile();
     this.getMessages();
    
    this.subscription = this.source.subscribe(val => {
          this.getMessages();
    });
  }

  getMessages() {
    this.documentService.getMessagesByIdDocument(this.docId)
      .subscribe((resp: Messages[] )=> {
       // console.log("chat response object chat =", resp);
       // this.messages = resp;
        //this.chatService.chat.push({id: this.authService.user.email, message: this.messages})
        if (this.messLength != resp.length) {
          this.chats = {id:  this.authService.user.email,  message: resp};
          this.messLength = this.chats.message.length;
          setTimeout(function () {
            document.querySelector(".chat-history").scrollBy({ top: 200, behavior: 'smooth' });
          }, 310)
        }
      })
  }
  public tabbed(val) {
  	this.openTab = val
  }

  // Get user Profile
  public getProfile() {
    this.chatService.getCurrentUser().subscribe(userProfile => this.profile = userProfile)
  }

  // User Chat
  public userChat(id:string){    
    //this.chatService.chatToUser(id).subscribe(chatUser => this.chatUser = chatUser)
    this.chatService.getChatHistory(id).subscribe(chats => {
      this.chats = chats
      console.log("user chats ",this.chats);
    })
  }
  
  // Send Message to User
  public sendMessage(form) {
    if(!form.value.message){
      this.error = true
      return false
    }
    this.error = false
    let chat = {
      sender: this.authService.user.email,
      receiver: null,
      message: form.value.message,
      docId: this.docId
    }
    this.saveMessage(chat) 
    this.chatText = ''
    // this.chatUser.seen = 'online'
    // this.chatUser.online = true
  }

   // Send Message to user
  private  saveMessage(chat) {
    let message: Messages = {
      sender: chat.sender,
      text: chat.message,
      time:`${ this.authService.user.name} ${today.toLowerCase()}`,
      documentuniqueId: chat.docId
    }
    this.documentService.createMessage(message)
      .subscribe(resp => {
       // this.chat.push()
        this.getMessages();
      })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  // searchTerm(term: any) {
  //   if(!term) return this.searchUsers = this.users
  //   term = term.toLowerCase();
  //   let user = []
  //   this.users.filter(users => {
  //     if(users.name.toLowerCase().includes(term)) {
  //       user.push(users)
  //     } 
  //   })
  //   this.searchUsers = user
  // }
    
}
