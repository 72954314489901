import { Property } from './property';
export class Dossier {
    dossierId: number;
    name: string;
    title: string;
    parentId: number;
    expand: boolean = false;
    children: Dossier[];
    description: string;
    dossierEncoded: string;
    canSendMail: String;
    smsReceiver: String;
    code: string;
    codedifAuto: boolean;
    close: boolean;
    clone: boolean = false;
    selected: boolean;
    class: string = "node-unselected";
    properties: Property[];
    icon: string;
    dataOnly: boolean;
    constructor(id: number,
        name: string,
        dossierEncoded: string,
        description: string,
        parentId: number,
        properties: Property[],
        code?: string,
        codedifAuto?: boolean,
        close?: boolean,
        selected?: boolean,
        clone?: boolean,
        dataOnly?: boolean
    ) {
        this.dossierId = id;
        this.description = description;
        this.name = name;
        this.parentId = parentId;
        this.properties = properties;
        this.dossierEncoded = dossierEncoded;
        this.code = code;
        this.codedifAuto = codedifAuto;
        this.close = close;
        this.selected = selected;
        this.clone = clone;
        this.dataOnly = dataOnly;
    }
}