<div class="container">
<div class="d-flex justify-content-end">
    <button mat-icon-button class="close-button " [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="black">close</mat-icon>
    </button>
</div>



    <div class="row">
        <div class="col" class="colViewer">



            <div class="mat-paginator-range-actions">

                <!---->
                <!---->
                <button mat-icon-button="" type="button" (click)="previous()"
                    class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-previous mat-icon-button mat-button-base"
                    aria-label="Previous page" title="document précédent">
                    <span class="mat-button-wrapper">
                        <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                        </svg>
                    </span>

                    <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
                    <span class="mat-button-focus-overlay"></span>
                </button>
                <!---->

                <div class="mat-paginator-range-label"> {{currentImageIndex}}/{{totalImages}} </div>


                <button mat-icon-button="" type="button" (click)="next()"
                    class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base"
                    aria-label="Next page" title="document suivant">
                    <span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false"
                            class="mat-paginator-icon">
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                        </svg>
                    </span>
                    <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
                    <span class="mat-button-focus-overlay"></span>
                </button>
                <!---->

            </div>


            <div *ngIf="viewer === 'pdf'">
                <ngx-doc-viewer [url]="fileUrl" viewer="pdf" style="width:100%;height:80vh"></ngx-doc-viewer>
            </div>

            <div class="mat-paginator-range-actions">

                <!---->
                <!---->
                <button mat-icon-button="" type="button" (click)="previous()"
                    class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-previous mat-icon-button mat-button-base"
                    aria-label="Previous page" title="document précédent">
                    <span class="mat-button-wrapper">
                        <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                        </svg>
                    </span>

                    <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
                    <span class="mat-button-focus-overlay"></span>
                </button>
                <!---->

                <div class="mat-paginator-range-label"> {{currentImageIndex}}/{{totalImages}} </div>


                <button mat-icon-button="" type="button" (click)="next()"
                    class="mat-focus-indicator mat-tooltip-trigger mat-paginator-navigation-next mat-icon-button mat-button-base"
                    aria-label="Next page" title="document suivant">
                    <span class="mat-button-wrapper"><svg viewBox="0 0 24 24" focusable="false"
                            class="mat-paginator-icon">
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                        </svg>
                    </span>
                    <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
                    <span class="mat-button-focus-overlay"></span>
                </button>
                <!---->

            </div>
        </div>

    </div>
</div>