import { Component, Input, ViewEncapsulation, ɵ_sanitizeHtml, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { DossierService } from '../../services/dossier.service';
import { Dossier } from '../../model/dossier';
import { RequestService } from '../../services/request.service'
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '../../services/auth.service';
import { Observable } from 'rxjs/Observable';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  public listDossiers: Dossier[];
  public dossier: Dossier;
  dossierFromWidget: Observable<Dossier[]>;
  panelOpenState = true;
  user = { name: null, email: null, roles: null };
  constructor(private router: Router,
    private requestService: RequestService,
    public navServices: NavService,
    private dossierService: DossierService,
    private authService: AuthService) {


    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    })
  }

  ngOnInit(): void {
    this.user = this.authService.user;
    this.dossierFromWidget = this.dossierService.getWidgetData();

  }
  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active;
    this.dossierService.currentDossierId = item.id;
    console.log("menu", this.menuItems);
    //this.launch(item.id);
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }
  onClick(doss: Dossier) {
    this.dossier = doss;
    //console.log(this.dossier);
  }
  onwClick(doss: Dossier) {
    console.log(doss);
  }


}
