<div class="bg-light">
  <div class="logo-wrapper ml-0"><a routerLink="/user/home"><img width="255px" height="82px"
        src="assets/images/logo/logo-vusa.png" alt=""></a></div>
</div>

<div class="sidebar custom-scrollbar">
  <div class="sidebar-user text-center">
    <!--<div><img class="img-60 rounded-circle" [src]="url? url:'/assets/images/user/1.jpg'" alt="#">
      <div class="profile-edit"><a>
          <i class="icofont icofont-pencil-alt-5"><input class="upload" type="file" (change)="readUrl($event)" /></i>
        </a></div>
    </div>-->
    <h6 class="mt-3 f-14">{{user.name}}</h6>
    <p>{{user.email}}</p>
  </div>


  <mat-accordion>

    <mat-expansion-panel (opened)=" panelOpenState=true" (closed)="panelOpenState = false" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          ESPACE DE TRAVAIL
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <ul class="sidebar-menu">
          <li *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
            <!-- Sub -->
            <a [routerLink]="'/user/widgets/'+menuItem.encoded" class="sidebar-header anchor"
              *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)">
              <app-feather-icons [icon]="menuItem.icon"></app-feather-icons><span>{{menuItem.title}}<span
                  class="badge badge-{{menuItem.badgeType}} ml-3"
                  *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
            </a>
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)=" panelOpenState=true" (closed)="panelOpenState = false" [expanded]="(dossierFromWidget | async)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          MENU
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div id="wMenu">

        <app-wtree [listDossiers]="dossierFromWidget | async" (dossEmitter)="onwClick($event)"></app-wtree>

      </div>
    </mat-expansion-panel>
  </mat-accordion>