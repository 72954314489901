import { Component, OnInit } from '@angular/core';
import { RequestService } from '../../services/request.service';
import { MetaData } from '../../model/metadata';
import { RequestItem } from '../../model/request.item';
import { DossierService } from '../../services/dossier.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
   requestValue: RequestItem[];
   requestResult: MetaData[][];
   requestSingle: string="";
  constructor(private requestService: RequestService,
    private dossierService: DossierService,
    private route: ActivatedRoute,
    private router: Router ) {
    
   }

  ngOnInit() {
    this.requestValue=this.requestService.requestValue;
  }
  delete(i: number){
    this.requestService.requestValue.splice(i,1);
    this.requestService.request.splice(i,1);
    }
    launch(){
      this.router.navigate(['/user/search/'+this.dossierService.currentDossierId]);

     /* 
     this.requestService.data.splice(0,this.requestService.data.length);
     this.requestService.columns.splice(0,this.requestService.columns.length);
          
      this.requestService.searchResult(
        this.dossierService.currentDossierId).subscribe(res=>{
          this.requestResult = res;
        this.requestService.requestResult=res;
        for (var i=0;i< res.header.length;i++){
          this.requestService.columns.push(res.header[i]);
        }
        for (var i=0;i< res.data.length;i++){
          this.requestService.data.push(res.data[i]);
        }
    });
   */
    }
}
