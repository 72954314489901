import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminAccueilComponent } from './admin-accueil/admin-accueil.component';
import { AdminModifDossComponent } from './admin-modif-doss/admin-modif-doss.component';
import { AdminReadDossComponent } from './admin-read-doss/admin-read-doss.component';
import { AdminListDossComponent } from './admin-list-doss/admin-list-doss.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeComponent } from './tree/tree.component';
import { PropertyComponent } from './property/property.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SharedModule } from '../shared/shared.module';
import { DragulaModule } from 'ng2-dragula';
import { AddPropertyComponent } from './add-property/add-property.component';
import { ReferenceComponent } from './reference/reference.component';
import { DuplicationComponent } from './duplication/duplication.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';

@NgModule({
  declarations: [
    AdminAccueilComponent, 
    AdminModifDossComponent, 
    AdminReadDossComponent, 
    DuplicationComponent,
    AdminListDossComponent, TreeComponent, PropertyComponent, AddPropertyComponent, ReferenceComponent, ManageUsersComponent, UtilisateurComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DragulaModule.forRoot()
  ],
  entryComponents:[AdminModifDossComponent,UtilisateurComponent],
  providers: [
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
  ]
})
export class AdminModule { }
