import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { Dossier } from '../../model/dossier';
import { DossierService } from '../../services/dossier.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-admin-modif-doss',
  templateUrl: './admin-modif-doss.component.html',
  styleUrls: ['./admin-modif-doss.component.scss']
})
export class AdminModifDossComponent implements OnInit, OnDestroy {
  /** TDOD: passer le dossier depuis le composent appelant le dialog via l'objet data de MatDialog */
  dossier: Dossier;
  listDossiers: Dossier[];
  user = {"name":null,"email":null,roles:null};
  //dossierForm: FormGroup;
  parent;
  sub$: Subscription;
  constructor(private dossierService: DossierService,
   private toastrService: ToastrService,
   private authService:AuthService,
   public dialogRef: MatDialogRef<AdminModifDossComponent>,

    @Inject(MAT_DIALOG_DATA) public data: Dossier
    ) {
      this.dossier = data;
      console.log(this.dossier);
     }

  ngOnInit(): void {
   //this.initForm();
   this.user=this.authService.user;
   this.listDossiers = this.dossierService.dossiers;
  }

  initForm() {
    if (this.dossier == undefined) {
      this.dossier= new Dossier(null, '','', '', 0, []);
    }
    /*this.dossierForm = this.fb.group({
      name: new FormControl(),
      description: new FormControl(),
      parentId: new FormControl()
    })*/
  }

  close(): void {
    this.dialogRef.close(false);
  }
  save(): void {
    if(this.dossier.dossierId==null){
     this.sub$ = this.dossierService.createnewdossier(this.dossier).subscribe(dos=>{
        this.dossier=dos;
        this.toastrService.success("Dossier \""+this.dossier.name+"\" sauvegardé avec succès.")
        this.dialogRef.close(true);
      },
      error=>{
        this.toastrService.error("Une erreur s'est produite lors de l'enregistrement du dossier")
        this.dialogRef.close(false);
      });
    }else{
      this.sub$ = this.dossierService.updateDossier(this.dossier).subscribe(dos=>{
        this.dossier=dos;
        this.toastrService.success("Dossier sauvegardé avec succès.")
        this.dialogRef.close(true);
      },
      error=>{
        this.toastrService.error("Une erreur s'est produite lors de l'enregistrement du dossier")
        this.dialogRef.close(false);
      });
    }    
  }
  newDossier(dos: Dossier){
    this.dossierService.createnewdossier(dos).subscribe(dossier=>{
      this.dossierService.getDossiersByParent(0,this.user).subscribe(
        doss=>{}
      )
    })
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }
}
