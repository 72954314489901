import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Notification } from '../model/notification';
import { Observable } from 'rxjs';
@Injectable({providedIn: 'root'})
export class NotificationService {
    constructor(private http: HttpClient){}

    /**
     * 
     * @param email 
     * @returns 
     */
    getListNotification(email: string) : Observable<Notification[]>{
        return this.http.get<Notification[]>(`${environment.backendUrl}notification/email/${email}`);
    }
}