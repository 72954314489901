
<div style="text-align:center">
    <h1>Advanced PDF Printing from Javascript</h1>
    <hr />
    <div>
        <div>
            <label for="txtPdfFile">PDF File URL:</label>
            <input type="text" name="txtPdfFile" id="txtPdfFile" value="https://neodynamic.com/temp/LoremIpsum.pdf" />
        </div>
        <div>
            <label for="lstPrinters">Printers:</label>
            <select name="lstPrinters" id="lstPrinters" onchange="showSelectedPrinterInfo();" ></select>
        </div>
        <div>
            <label for="lstPrinterTrays">Supported Trays:</label>
            <select name="lstPrinterTrays" id="lstPrinterTrays" ></select>
        </div>
        <div>
            <label for="lstPrinterPapers">Supported Papers:</label>
            <select name="lstPrinterPapers" id="lstPrinterPapers" ></select>
        </div>
        <div>
            <label for="lstPrintRotation">Print Rotation (Clockwise):</label>
            <select name="lstPrintRotation" id="lstPrintRotation" >
                <option>None</option>
                <option>Rot90</option>
                <option>Rot180</option>
                <option>Rot270</option>
            </select>
        </div>
    </div>
    <div>
        <div>
            <label for="txtPagesRange">Pages Range: [e.g. 1,2,3,10-15]</label>
            <input type="text"  id="txtPagesRange">
        </div>
        <!--
        <div>
            <div >
                <label><input id="chkPrintInReverseOrder" type="checkbox" value="">Print In Reverse Order?</label>
            </div>
        </div>
        <div>
            <div >
                <label><input id="chkPrintAnnotations" type="checkbox" value="">Print Annotations? <span><em>Windows Only</em></span></label>
            </div>
        </div>
        <div>
            <div >
                <label><input id="chkPrintAsGrayscale" type="checkbox" value="">Print As Grayscale? <span><em>Windows Only</em></span></label>
            </div>
        </div>
    -->
    </div>
    <hr />
    <button type="button" onclick="loadSittings();">Load settings</button>
    <button type="button" onclick="print();">Print Now...</button>
    
    
</div>

